<template>
  <div id="app">
    <router-view />
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <span class="text-muted" v-if="path !== 'admin'">
              &copy; {{ year }} Joint Admissions and Matriculation Board. <br />All rights reserved. &nbsp;
              <!--    <a href="http://up-ng.com/index.php?id=33" target="_blank">UP Privacy Policy</a> -->
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <span class="text-muted">Powered by <a href="http://coregrade.co" target="_blank">Coregrade</a>.</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  computed: {
    year() {
      let d = new Date()
      return d.getFullYear()
    },

    authUser() {
      return this.$store.state.authUser
    },

    path() {
      let paths = window.location.pathname.split('/')
      return paths[1]
    }
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
  /* font-family: 'Century Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif !important; */
}
.footer {
  font-size: 0.7rem;
  /* bottom: 0 !important; */
}
@media (max-width: 576px) {
  .footer {
    text-align: right;
  }
}
</style>
