<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">{{ test.name }}</div>
            <div class="card-body">
              <h5>Introduction</h5>
              <div>
                <p>{{ test.introduction }}</p>
              </div>
              <!--  -->
              <h5>Description</h5>
              <div v-html="test.description"></div>
              <!--  -->
              <h5>Instructions</h5>
              <div class="pl-5 pr-5" v-html="test.prerequisite"></div>
            </div>
            <div class="card-footer">
              <button class="btn btn-danger" @click="start()">Start new attempt</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Previous Attempt -->
      <div class="row mt-5" v-if="attempts.length > 0">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">Previous <small>Attempts</small></div>
            <div class="card-body">
              <table class="table table-striped" style="font-size: 13px">
                <tr>
                  <!-- <th>SN</th> -->
                  <th>Date/Time</th>
                  <th>Key</th>
                  <th>Questions</th>
                  <th>Answered</th>
                  <th>Minutes set</th>
                  <th>Minutes used</th>
                  <th>Score</th>
                </tr>
                <tr v-for="(attempt, index) in attempts" :key="index">
                  <!-- <td>{{ index + 1 }}</td> -->
                  <td class="text-left">{{ attempt.created_at | formatDateTime }}</td>
                  <td class="text-left">
                    <router-link :to="{ name: 'AssessmentRenderCandidate', params: { attemptUUID: attempt.uuid } }">
                      {{ attempt.uuid }}
                    </router-link>
                  </td>
                  <td class="text-center">{{ attempt.totalQuestionsGiven }}</td>
                  <td class="text-center">{{ attempt.totalQuestionsAnswered }}</td>
                  <td class="text-center">{{ attempt.totalMinutesGiven }}</td>
                  <td class="text-center">{{ attempt.totalMinutesUsed }}</td>
                  <td class="text-center">{{ attempt.score }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Previous Attempt -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AssessmentDetailsCandidate',
  data() {
    return {
      test: {},
      attempts: {}
    }
  },

  mounted() {
    axios.get(`/candidate/tests/${this.uuid}`).then(res => {
      this.test = res.data.test
      this.attempts = res.data.attempts
    })
  },

  methods: {
    start(uuid) {
      axios.get(`/candidate/tests/start/${this.uuid}`).then(res => {
        if (res.data.status == 'success') {
          this.$router.push({ name: 'AssessmentRenderCandidate', params: { attemptUUID: res.data.attemptUUID } })
        }
      })
    }
  },

  computed: {
    uuid() {
      return this.$route.params.uuid
    }
  }
}
</script>
