<template>
  <div>
    <button v-b-modal="modalID" class="btn btn-sm btn-outline-secondary mr-2">Edit Answer {{ answerIndex + 1 }}</button>

    <b-modal :id="modalID" :title="'Edit answer' + (answerIndex + 1)" size="lg" hide-footer no-close-on-backdrop>
      <!-- <p class="my-4">{{ testUUID }}</p> -->
      <b-overlay :show="showOverlay" rounded="sm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="answerContent">Content</label>
            <vue-editor v-model="answerContent" :editorToolbar="editorToolbar" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <div class="text-danger text-xs" v-show="$v.answerContent.$error">
              <span v-if="!$v.answerContent.required">Content is required.</span>
            </div>
          </div>

          <div class="form-group">
            <label for="answerFeedback">Feedback</label>
            <vue-editor v-model="answerFeedback" :editorToolbar="editorToolbar" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <!--  <div class="text-danger text-xs" v-show="$v.answerFeedback.$error">
            <span v-if="!$v.answerFeedback.required">Feedback is required.</span>
          </div> -->
          </div>

          <button class="btn btn-success btn-block btn-lg" type="submit">Submit</button>
        </form>

        <template #overlay>
          <div class="text-center">
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
            <p id="cancel-label">Please wait...</p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import editor from '../../../../helper/editor'
import { VueEditor } from 'vue2-editor'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    answer: Object,
    answerIndex: Number,
    questionIndex: Number
  },

  data() {
    return {
      showOverlay: false,
      modalID: 'edit-answer-' + this.answer.uuid,
      answerContent: this.answer.content,
      answerFeedback: this.answer.feedback,

      editorToolbar: editor.toolbar
    }
  },

  methods: {
    handleSubmit() {
      this.showOverlay = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showOverlay = false
        return
      }

      let formData = new FormData()
      formData.append('content', this.answerContent)
      formData.append('feedback', this.answerFeedback)
      axios.post(`/authoring/answers/edit/${this.answer.uuid}`, formData).then(res => {
        if (res.data.status === 'success') {
          this.$emit('answerEdited', { answer: res.data.answer, questionIndex: this.questionIndex, answerIndex: this.answerIndex })
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('', res.data.message, 'error')
        }
        this.showOverlay = false
      })
    },

    handleImageAdded: editor.handleImageAdded
  },

  validations: {
    answerContent: { required }
    //answerFeedback: { required },
  },

  components: {
    VueEditor
  }
}
</script>

<style scoped>
/* .btn-sm {
  text-transform: uppercase;
  font-size: 11px;
}
.btn-outline-secondary {
  border-color: #d9dfe4;
} */
</style>
