<template>
  <div class="container mt-n10">
    <div class="row">
      <div class="col-md-12">
        <transition name="custom-classes-transition" enter-active-class="animated bounceInLeft" leave-active-class="animated bounceOutRight">
          <div class="card bg-light mb-3" v-show="animate">
            <div class="card">
              <div class="card-header"><BackIcon></BackIcon> {{ course.name }}</div>
              <div class="card-body">
                <img :src="course.image" style="height: 300px" class="mb-4" />
                <table class="table">
                  <tr>
                    <th>Name</th>
                    <td>{{ course.name }}</td>
                  </tr>
                  <tr>
                    <th>Price</th>
                    <td>{{ course.price | currency }}</td>
                  </tr>
                  <tr>
                    <th>Introduction</th>
                    <td>{{ course.introduction }}</td>
                  </tr>
                  <tr>
                    <th>Prerequisite</th>
                    <td v-html="course.prerequisite"></td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td v-html="course.description"></td>
                  </tr>
                  <tr>
                    <th>Key</th>
                    <td>
                      <table>
                        <tr>
                          <td style="border-top: none">{{ course.uuid }}</td>
                          <td style="border-top: none">
                            <b-button @click="copy" id="button-1" class="btn btn-sm">
                              <i class="icon icon-copy"></i>
                            </b-button>
                            <b-tooltip target="button-1" :show.sync="show" title="Copied!" :triggers="'click'"></b-tooltip>
                            <b-tooltip target="button-1" :show.sync="hide" title="Copy course key" placement="top" :triggers="'hover'"></b-tooltip>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="card-footer">
                <table>
                  <tr>
                    <td>
                      <router-link :to="{ name: 'CourseEditAuthor', params: { uuid: course.uuid } }" class="btn btn-sm btn-outline-primary mr-1" :title="'Edit ' + course.name + ' Details'">Edit course</router-link>
                    </td>
                    <td>
                      <router-link :to="{ name: 'LessonsAuthor', params: { courseUUID: course.uuid } }" class="btn btn-sm btn-outline-primary mr-1" :title="course.name + ' lessons'">Lessons</router-link>
                    </td>
                    <!--  <td>
                      <button v-on:click="deleteCourse(course.uuid)" class="btn btn-sm btn-outline-danger mr-1" :title="'Delete ' + course.name">Delete</button>
                    </td> -->
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'CourseDetailsAuthor',

  data() {
    return {
      animate: true,
      show: false,
      hide: true,
      course: true
    }
  },

  mounted() {
    axios
      .get('/author/courses/' + this.$route.params.uuid)
      .then(res => {
        this.course = res.data.course
      })
      .catch(error => {})
  },

  methods: {
    copy() {
      this.show = true
      this.hide = false

      this.$copyText(this.course.uuid).then(
        function (e) {
          //alert("Copied");
          //console.log(e);
        },
        function (e) {
          //alert("Can not copy");
          //console.log(e);
        }
      )
    },

    deleteCourse(uuid) {
      axios.post('/authoring/courses/delete/' + uuid).then(res => {
        if (res.data.status === 'success') {
          this.$swal('Done!', res.data.message, 'success')
          this.$router.push({ name: 'CoursesAuthor' })
        } else {
          this.$swal('Done!', res.data.message, 'error')
        }
      })
    }
  },

  components: { BackIcon }
}
</script>

<style></style>
