<template>
  <div id="CustomerLayout">
    <b-navbar toggleable="lg" type="light" variant="light">
      <!-- Logo -->
      <router-link :to="{ name: 'DashboardAuthor' }" class="navbar-brand text-muted">
        <img src="../assets/img/JAMB-Logo.png" alt="" height="50" />
        &nbsp;Author
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link :to="{ name: 'CoursesAuthor' }" class="nav-link">
              <b-icon-book></b-icon-book>
              Courses
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'TestsAuthor' }" class="nav-link">
              <b-icon-book></b-icon-book>
              Assessments
            </router-link>
          </li>
          <!-- <b-nav-item-dropdown>
            <template #button-content> <b-icon-book class="text-mute"></b-icon-book> Courses </template>
            <li>
              <router-link :to="{ name: 'CoursesAuthor' }" class="dropdown-item"><b-icon-arrow-right-short></b-icon-arrow-right-short> View</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CourseCreateAuthor' }" class="dropdown-item"> <b-icon-arrow-right-short></b-icon-arrow-right-short> Create</router-link>
            </li>
          </b-nav-item-dropdown> -->
          <!--  -->

          <!-- <b-nav-item-dropdown>
            <template #button-content> <b-icon-stickies class="text-mute"></b-icon-stickies> Bundle Courses </template>

            <li>
              <router-link :to="{ name: 'SpecializationsAuthor' }" class="dropdown-item"><b-icon-arrow-right-short></b-icon-arrow-right-short> View</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CourseCreateAuthor' }" class="dropdown-item"> <b-icon-arrow-right-short></b-icon-arrow-right-short> Create</router-link>
            </li>
          </b-nav-item-dropdown> -->
          <!--  -->

          <!-- <b-nav-item-dropdown>
            <template #button-content> <b-icon-question-circle class="text-mute"></b-icon-question-circle> Assessments </template>
            <li>
              <router-link :to="{ name: 'TestCreateAuthor' }" class="dropdown-item"> <b-icon-arrow-right-short></b-icon-arrow-right-short> Create</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'TestsAuthor' }" class="dropdown-item"><b-icon-arrow-right-short></b-icon-arrow-right-short> View</router-link>
            </li>
          </b-nav-item-dropdown> -->
          <!--  -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.name }} </template>
            <li>
              <router-link :to="{ name: 'ProfileAuthor' }" class="dropdown-item">
                <b-icon-person></b-icon-person>
                Profile
              </router-link>
            </li>
            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import Logout from '@/components/Logout.vue'

export default {
  name: 'AuthorLayout',

  components: {
    Logout
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },

    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
