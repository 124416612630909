<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header"><BackIcon></BackIcon> {{ test.name }}</div>
          <div class="card-body">
            <!--  -->
            <!-- <p>
                  <router-link :to="'/tests/edit/' + test.uuid" class="btn btn-sm btn-outline-primary" :title="'Edit ' + test.name + ' Details'">Edit</router-link>&nbsp;
                  <router-link :to="'/lessons/' + test.uuid" class="btn btn-sm btn-outline-danger" :title="'Lessons ' + test.name + ' Details'">Lessons</router-link>&nbsp;
                  <button v-on:click="deleteTest(test.uuid)" class="btn btn-sm btn-outline-default" :title="'Delete ' + test.name">
                    <i class="icon icon-trash-alt"></i>
                  </button>
                </p> -->
            <!--  -->
            <img :src="test.image" style="width: 800px" />
            <table class="table">
              <tr>
                <th>Name</th>
                <td>{{ test.name }}</td>
              </tr>
              <tr>
                <th>Introduction</th>
                <td v-html="test.introduction"></td>
              </tr>

              <tr>
                <th>Prerequisite</th>
                <td v-html="test.prerequisite"></td>
              </tr>
              <tr>
                <th>Description</th>
                <td v-html="test.description"></td>
              </tr>
            </table>
          </div>

          <div class="card-footer">
            <table>
              <tr>
                <td>
                  <router-link :to="{ name: 'TestEditAuthor', params: { uuid: test.uuid } }" class="btn btn-sm btn-outline-primary mr-1" :title="'Edit ' + test.name + ' Details'">Edit test</router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'QuestionsAuthor', params: { testUUID: test.uuid } }" class="btn btn-sm btn-outline-primary mr-1" :title="test.name + ' lessons'">Questions</router-link>
                </td>
                <!--  <td>
                      <button v-on:click="deleteCourse(course.uuid)" class="btn btn-sm btn-outline-danger mr-1" :title="'Delete ' + course.name">Delete</button>
                    </td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'TestDetailsAuthor',

  data() {
    return {
      test: {}
    }
  },

  beforeMount() {
    axios
      .get('/author/tests/' + this.$route.params.uuid)
      .then(res => {
        this.test = res.data.test
      })
      .catch(error => {})
  },

  computed: {},

  methods: {
    deleteTest(uuid) {
      axios.post('/authoring/tests/delete/' + uuid).then(res => {
        if (res.data.status === 'success') {
          this.$swal('Done!', res.data.message, 'success')
          this.$router.push({ name: 'TestsAuthor' })
        } else {
          this.$swal('Done!', res.data.message, 'error')
        }
      })
    }
  },

  components: { BackIcon }
}
</script>
