<template>
  <div class="container mt-n10">
    <div class="row">
      <div class="col-md-12">
        <transition name="custom-classes-transition" enter-active-class="animated bounceInLeft" leave-active-class="animated bounceOutRight">
          <div class="card bg-light mb-3" v-show="animate">
            <div class="card">
              <div class="card-header"><BackIcon></BackIcon> {{ specialization.name }}</div>
              <div class="card-body">
                <img :src="specialization.image" style="height: 300px" class="mb-4" />
                <table class="table">
                  <tr>
                    <th>Name</th>
                    <td>{{ specialization.name }}</td>
                  </tr>
                  <tr>
                    <th>Introduction</th>
                    <td v-html="specialization.introduction"></td>
                  </tr>

                  <tr>
                    <th>Prerequisite</th>
                    <td v-html="specialization.prerequisite"></td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td v-html="specialization.description"></td>
                  </tr>
                </table>
              </div>

              <div class="card-footer">
                <table>
                  <tr>
                    <td>
                      <router-link :to="{ name: 'SpecializationEditAuthor', params: { uuid: specialization.uuid } }" class="btn btn-sm btn-outline-primary mr-1" :title="'Edit ' + specialization.name + ' Details'">Edit bundle course</router-link>
                    </td>
                    <td>
                      <router-link :to="{ name: 'LessonsAuthor', params: { specializationUUID: specialization.uuid } }" class="btn btn-sm btn-outline-primary mr-1" :title="specialization.name + ' lessons'">Lessons</router-link>
                    </td>
                    <!--  <td>
                      <button v-on:click="deleteCourse(specialization.uuid)" class="btn btn-sm btn-outline-danger mr-1" :title="'Delete ' + specialization.name">Delete</button>
                    </td> -->
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'SpecializationDetailsAuthor',

  components: { BackIcon },

  mounted() {
    axios
      .get('/author/specializations/' + this.$route.params.uuid)
      .then(res => {
        this.specialization = res.data.specialization
      })
      .catch(error => {})
  },

  data() {
    return {
      animate: true,
      show: false,
      hide: true,
      specialization: {}
    }
  },

  methods: {
    copy() {
      this.show = true
      this.hide = false

      this.$copyText(this.specialization.uuid).then(
        function (e) {
          //alert("Copied");
          //console.log(e);
        },
        function (e) {
          //alert("Can not copy");
          //console.log(e);
        }
      )
    }
  }
}
</script>

<style></style>
