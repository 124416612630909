import CoursesCandidate from '../views/candidate/CoursesCandidate.vue'
import CourseDetailsCandidate from '../views/candidate/CourseDetailsCandidate.vue'
import CourseLessonsCandidate from '../views/candidate/CourseLessonsCandidate.vue'

import AssessmentsCandidate from '../views/candidate/AssessmentsCandidate.vue'
import AssessmentDetailsCandidate from '../views/candidate/AssessmentDetailsCandidate.vue'
import AssessmentRenderCandidate from '../views/candidate/AssessmentRenderCandidate.vue'
import AssessmentResultCandidate from '../views/candidate/AssessmentResultCandidate.vue'

import ProfileCandidate from '../views/Profile.vue'

import ChangePasswordCandidate from '../views/ChangePassword.vue'
//import UserDetailsCandidate from '../views/admin/user/UserDetailsCandidate.vue'

import CandidateLayout from '../layouts/Candidate.vue'
import auth from '../helper/auth'

// user Candidate

const candidateRoutes = [
  {
    path: '/candidate',
    component: CandidateLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      // COURSES
      {
        path: '/',
        name: 'CoursesCandidate',
        component: CoursesCandidate
      },
      {
        path: 'course/:uuid',
        name: 'CourseDetailsCandidate',
        component: CourseDetailsCandidate
      },
      {
        path: 'class/:courseSlug/:lessonUUID',
        name: 'CourseLessonsCandidate',
        component: CourseLessonsCandidate
      },

      /* ASSESSME NTS */
      {
        path: 'assessments',
        name: 'AssessmentsCandidate',
        component: AssessmentsCandidate
      },
      {
        path: 'assessment/:uuid',
        name: 'AssessmentDetailsCandidate',
        component: AssessmentDetailsCandidate
      },
      {
        path: 'assessment-renderer/:attemptUUID',
        name: 'AssessmentRenderCandidate',
        component: AssessmentRenderCandidate
      },
      {
        path: '/assessment-result/:attemptUUID',
        name: 'AssessmentResultCandidate',
        component: AssessmentResultCandidate
      },

      // OTHERS
      {
        path: 'profile',
        name: 'ProfileCandidate',
        component: ProfileCandidate
      },
      {
        path: 'change-password',
        name: 'ChangePasswordCandidate',
        component: ChangePasswordCandidate
      }
    ]
  }
]

export default candidateRoutes
