<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-light">
          <div class="card-header">
            <BackIcon></BackIcon> Edit Test <small>{{ test.name }}</small>
          </div>
          <div class="card-body">
            <div class="card-body">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">
                        Name
                        <span class="text-danger">*</span>
                      </label>
                      <input v-model="test.name" type="text" class="form-control" :class="{ 'is-invalid': $v.test.name.$error, 'is-valid': !$v.test.name.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.test.name.required">Name is required.</span>
                        <span v-if="!$v.test.name.minLength">Name must be at least {{ $v.test.name.$params.minLength.min }} letters.</span>
                        <span v-if="!$v.test.name.maxLength">Name must be at most {{ $v.test.name.$params.maxLength.max }} letters.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="name">
                        Price
                        <span class="text-danger">*</span>
                      </label>
                      <input v-model="test.price" type="number" class="form-control" :class="{ 'is-invalid': $v.test.price.$error, 'is-valid': !$v.test.price.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.test.price.required">Price is required.</span>
                        <span v-if="!$v.test.price.numeric">Price must be numeric.</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label for="name">
                            Number of minutes to complete an attempt.
                            <span class="text-danger">*</span>
                          </label>
                          <input v-model="test.timeSet" type="number" class="form-control" :class="{ 'is-invalid': $v.test.timeSet.$error, 'is-valid': !$v.test.timeSet.$invalid }" />
                          <div class="invalid-feedback">
                            <span v-if="!$v.test.timeSet.required">Number of minutes to complete an attempt is required.</span>
                            <span v-if="!$v.test.timeSet.numeric">Number of minutes to complete an attempt must be numeric.</span>
                            <span v-if="!$v.test.timeSet.minValue">Number of minutes to complete an attempt cannot be less than 5minutes.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="name">
                            Number of questions to deliver per attempt.
                            <span class="text-danger">*</span>
                          </label>
                          <input v-model="test.noOfQuestionsToRender" type="number" class="form-control" :class="{ 'is-invalid': $v.test.noOfQuestionsToRender.$error, 'is-valid': !$v.test.noOfQuestionsToRender.$invalid }" />
                          <div class="invalid-feedback">
                            <span v-if="!$v.test.noOfQuestionsToRender.required">Number of questions to deliver per attempt is required.</span>
                            <span v-if="!$v.test.noOfQuestionsToRender.numeric">Number of questions to deliver per attempt must be numeric.</span>
                            <span v-if="!$v.test.noOfQuestionsToRender.minValue">Number of questions to deliver per attempt cannot be less than 5 questions.</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="introduction">
                        Introduction
                        <span class="text-danger">*</span>
                      </label>
                      <textarea v-model="test.introduction" class="form-control" :class="{ 'is-invalid': $v.test.introduction.$error, 'is-valid': !$v.test.introduction.$invalid }"></textarea>
                      <div class="invalid-feedback">
                        <span v-if="!$v.test.introduction.required">Introduction is required.</span>
                        <span v-if="!$v.test.introduction.minLength">Introduction must be at least {{ $v.test.introduction.$params.minLength.min }} letters.</span>
                        <span v-if="!$v.test.introduction.maxLength">Introduction must be at most {{ $v.test.introduction.$params.maxLength.max }} letters.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="prerequisite">
                        Prerequisite
                        <span class="text-danger">*</span>
                      </label>
                      <vue-editor v-model="test.prerequisite" :editorToolbar="editorToolbar" :class="{ 'is-invalid': $v.test.prerequisite.$error, 'is-valid': !$v.test.prerequisite.$invalid }"></vue-editor>
                      <input v-model="test.prerequisite" type="hidden" class="form-control" :class="{ 'is-invalid': $v.test.prerequisite.$error, 'is-valid': !$v.test.prerequisite.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.test.prerequisite.required">Prerequisite is required.</span>
                        <span v-if="!$v.test.prerequisite.minLength">Prerequisite must be at least {{ $v.test.prerequisite.$params.minLength.min }} letters.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="description">
                        Description
                        <span class="text-danger">*</span>
                      </label>
                      <vue-editor v-model="test.description" :editorToolbar="editorToolbar" :class="{ 'is-invalid': $v.test.description.$error, 'is-valid': !$v.test.description.$invalid }"></vue-editor>
                      <input v-model="test.description" type="hidden" class="form-control" placeholder="e.g. Mark" :class="{ 'is-invalid': $v.test.description.$error, 'is-valid': !$v.test.description.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.test.description.required">Description is required.</span>
                        <span v-if="!$v.test.description.minLength">Description must be at least {{ $v.test.description.$params.minLength.min }} letters.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <i class="fa fa-paperclip"></i> Test Artwork
                      <!-- <input type="file" ref="file" multiple="multiple" /> -->
                      <input type="file" ref="file" />

                      <p class="help-block"><b>File Type:</b> jpg,jpeg,png | <b>Max file size:</b> 2MB</p>
                    </div>
                    <!-- end col-md-12 -->
                  </div>
                </div>

                <button :disabled="isDisabled" class="btn btn-danger" type="submit" id="submitButton">
                  <i class="far fa-paper-plane"></i>
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import BackIcon from '@/components/BackIcon.vue'
import { required, minValue, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'TestEditAuthor',

  data() {
    return {
      test: {},
      submitted: false,
      isDisabled: false,
      animate: true,
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  validations: {
    test: {
      name: { required, minLength: minLength(5), maxLength: maxLength(250) },
      introduction: { required, minLength: minLength(25), maxLength: maxLength(400) },
      prerequisite: { required, minLength: minLength(25) },
      description: { required, minLength: minLength(25) },
      price: { required, numeric },
      timeSet: { required, numeric, minValue: minValue(5) },
      noOfQuestionsToRender: { required, numeric, minValue: minValue(5) }
    }
  },

  components: {
    VueEditor,
    BackIcon
  },

  mounted() {
    axios
      .get('/author/tests/' + this.$route.params.uuid)
      .then(res => {
        this.test = res.data.test
        /* this.test.name = res.data.test.name //this.user.last_name = res.data.Test.name;
        this.test.introduction = res.data.test.introduction
        this.test.description = res.data.test.description
        this.test.prerequisite = res.data.test.prerequisite */
      })
      .catch(error => {
        // handle error
      })
  },

  computed: {
    testType() {
      return 'practice-test' // this.$route.query.type
    },

    s3() {
      return this.$store.state.s3
    }
  },

  methods: {
    handleSubmit(e) {
      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isDisabled = false
        return
      }
      this.submitted = true
      this.isDisabled = true
      let formData = new FormData()
      let allowedExtensions = ['jpg', 'jpeg', 'png']
      /* if (this.$refs.file.files.length < 1) {
        this.submitted = false;
        this.isDisabled = false;
        this.$swal("Oops!", "Test artwork is require. Please a image for your test.", "error");
        return;
      } */
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i]
        formData.append('file', file)
        // Form Validate ...
        let fileName = file.name
        let fileExtension = fileName.replace(/^.*\./, '')
        if (allowedExtensions.includes(fileExtension) == false) {
          let msg = '.' + fileExtension + ' file not allowed. Please replace ' + file.name
          this.$swal('Oops!', msg, 'error')
          this.submitted = false
          this.isDisabled = false
          return
        }
        if (file.size > 2 * 1000 * 1000) {
          let msg = file.name + ' is larger than the allowed file size of 5MB.'
          this.$swal('Oops!', msg, 'error')
          this.submitted = false
          this.isDisabled = false
          return
        }
      }

      formData.append('name', this.test.name)
      formData.append('price', this.test.price)
      formData.append('timeSet', this.test.timeSet)
      formData.append('noOfQuestionsToRender', this.test.noOfQuestionsToRender)
      formData.append('introduction', this.test.introduction)
      formData.append('prerequisite', this.test.prerequisite)
      formData.append('description', this.test.description)
      formData.append('type', this.testType) // practice-test, practice-test, live-test

      axios
        .post(`/author/tests/edit/${this.$route.params.uuid}`, formData)
        .then(res => {
          this.submitted = false

          this.isDisabled = false
          if (res.data.status === 'success') {
            this.$v.$reset()
            this.$swal('Done!', res.data.message, 'success')
            this.$router.push({ name: 'TestDetailsAuthor', params: { uuid: this.$route.params.uuid } })
          } else {
            this.$swal('Done!', res.data.message, 'error')
          }
        })
        .catch(err => {})
    }
  }
}
</script>
