<template>
  <!-- Createn new video lesson -->
  <div>
    <a href="#" class="btn btn-secondary btn-sm mr-1" v-b-modal="modalID">
      <i class="icon icon-file-pdf"></i>
      Add PDF Lesson
    </a>

    <b-modal title="Create video" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <form @submit.prevent="handleCreate" class="mt-4">
        <div class="form-group">
          <input type="text" class="form-control" v-model="lesson.name" placeholder="PDF Title" :class="{ 'is-invalid': $v.lesson.name.$error, 'is-valid': !$v.lesson.name.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.lesson.name.required">PDF title is required.</span>
            <span v-if="!$v.lesson.name.minLength">PDF title must be at least {{ $v.lesson.name.$params.minLength.min }} letters.</span>
          </div>
        </div>

        <div class="form-group">
          <vue-editor v-model="lesson.description" :editorToolbar="editorToolbar"></vue-editor>
          <!-- <textarea class="form-control" v-model="lesson.description" cols="30" rows="2" placeholder="PDF Description" :class="{ 'is-invalid': $v.lesson.description.$error, 'is-valid': !$v.lesson.description.$invalid }"></textarea> -->
          <div class="text-danger" v-show="$v.lesson.description.$error">
            <span v-if="!$v.lesson.description.required">Video description is required.</span>
            <span v-if="!$v.lesson.description.minLength">Video description must be at least {{ $v.lesson.description.$params.minLength.min }} letters.</span>
          </div>
        </div>
        <div class="form-group">
          <!-- <input v-model="lesson.file" type="file" class="form-control" placeholder="PDF File" /> -->
        </div>
        <button type="submit" class="btn btn-secondary">{{ createButton }}</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const btnLabel = 'Submit'

export default {
  props: {
    topicUUID: {},
    courseUUID: {},
    topicIndex: Number
  },

  data() {
    return {
      lesson: {
        name: '',
        description: ''
      },
      createButton: btnLabel,
      isDisabled: false,
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ],
      modalID: 'create-pdf-' + this.topicUUID
    }
  },

  methods: {
    handleCreate(e) {
      this.isDisabled = true
      this.createButton = 'Working...please wait!'

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.createButton = btnLabel
        this.isDisabled = false
        return
      }

      axios
        .post('/authoring/lessons/create/pdf/' + this.courseUUID + '/' + this.topicUUID, this.lesson)
        .then(res => {
          this.isDisabled = false
          if (res.data.status === 'success') {
            // if post success
            this.$bvModal.hide(this.modalID) // Hide modal after success
            this.$v.$reset()
            this.lesson = {}
            this.createButton = btnLabel
            this.$swal('Done!', res.data.message, 'success')
            // emit back an object contain the topic index and the newly created lesson from server response
            this.$emit('lessonCreated', { topicIndex: this.topicIndex, newLesson: res.data.lesson })
          } else {
            this.$swal('Nope!', res.data.message, 'error')
            this.createButton = btnLabel
          }
        })
        .catch(error => {
          // handle error
        })
    }
  },

  validations: {
    lesson: {
      name: { required, minLength: minLength(3) },
      description: { required, minLength: minLength(5) }
    }
  },

  components: {
    VueEditor
  }
}
</script>

<style></style>
