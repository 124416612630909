import PasswordResetNew from '../views/PasswordResetNew.vue'
import PasswordReset from '../views/PasswordReset.vue'
import PageNotFound from '../views/PageNotFound.vue'

import Register from '../views/Register.vue'
import Login from '../views/Login.vue'

const publicRoutes = [
  {
    path: '/',
    component: () => import('../layouts/Public.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: Login
      },

      {
        path: '/login',
        name: 'Login',
        component: Login
        //beforeEnter: auth.ifNotAuthenticated,
      },
      /* {
        path: '/register',
        name: 'Register',
        component: Register
      }, */

      {
        path: '/password-reset',
        name: 'PasswordReset', //
        component: PasswordReset
        //beforeEnter: auth.ifNotAuthenticated,
      },

      {
        path: '/password-reset/token/:token',
        name: 'PasswordResetNew', //
        component: PasswordResetNew
        //beforeEnter: auth.ifNotAuthenticated,
      },

      {
        path: '/*',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  }
]

export default publicRoutes
