<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">Courses</div>
          <div class="card-body-">
            <vue-good-table :columns="columns" :rows="courses" :line-numbers="true" :search-options="searchOptions" :pagination-options="paginationOptions" styleClass="vgt-table condensed myGoodTable">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'Name'">
                  <router-link :to="{ name: 'CourseDetailsAuthor', params: { uuid: props.row.UUID } }">
                    <b>{{ props.row.Name }}</b>
                  </router-link>
                </span>
                <!--  <span v-if="props.column.field == 'Price'">{{ props.row.Price | currency }}</span> -->
                <span v-if="props.column.field == 'TotalLectures'">{{ props.row.TotalLectures }}</span>
                <!--  <span v-if="props.column.field == 'Rating'">
                  <star-rating :rating="props.row.Rating" :read-only="true" :show-rating="false" :increment="0.5" :star-size="15" class="mb-3" />
                </span> -->
                <span v-if="props.column.field == 'TotalReviews'">{{ props.row.TotalReviews }}</span>
                <span v-if="props.column.field == 'TotalEntrollments'">{{ props.row.TotalEntrollments }}</span>
                <span v-if="props.column.field == 'CreatedAt'">{{ props.row.CreatedAt | formatDate }}</span>

                <span v-if="props.column.field == 'Actions'">
                  <b-dropdown id="dropdown-1" text="Actions" class="btn-xs" size="xs">
                    <b-dropdown-item :to="{ name: 'CourseEditAuthor', params: { uuid: props.row.UUID } }">Edit</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'CourseDetailsAuthor', params: { uuid: props.row.UUID } }">Details</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'LessonsAuthor', params: { courseUUID: props.row.UUID } }">Lessons</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'ResourceStudentsAuthor', params: { resourceUUID: props.row.UUID, resourceType: 'courses' } }">Students</b-dropdown-item>
                  </b-dropdown>
                </span>
              </template>
              <div slot="table-actions">
                <router-link :to="{ name: 'CourseCreateAuthor' }" class="btn btn-success btn-sm mr-2">Create</router-link>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CoursesAuthor',

  data() {
    return {
      animate: true,
      courses: []
    }
  },
  mounted() {
    axios
      .get('/author/courses')
      .then(res => {
        this.courses = res.data.courses
      })
      .catch(error => {})
  },

  methods: {
    deleteCourse(course, props) {
      this.$swal({
        title: 'Are you sure?',
        text: `This action will delete "${course.Name}" lesson and its content.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          axios.post('/author/courses/delete/' + course.UUID).then(res => {
            if (res.data.status == 'success') {
              this.courses.splice(props.index, 1)
              this.$swal('Deleted!', res.data.message, 'success')
            }
          })
        }
      })
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'Name'
        },
        /* {
          label: 'Price',
          field: 'Price'
        }, */
        {
          label: 'Lessons',
          field: 'TotalLectures'
        },
        /*  {
          label: 'Rating',
          field: 'Rating'
        }, */
        {
          label: 'Reviews',
          field: 'TotalReviews'
        },
        {
          label: 'Students',
          field: 'TotalEntrollments'
        },
        {
          label: 'Date',
          field: 'CreatedAt'
        },

        {
          label: '',
          field: 'Actions',
          sortable: false
        }
      ]
      return cols
    },

    searchOptions() {
      return {
        enabled: true,
        trigger: 'enter'
      }
    },

    paginationOptions() {
      return {
        enabled: true,
        perPage: 20
      }
    }
  }
}
</script>

<style></style>
