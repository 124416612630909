<template>
  <div>
    <div>
      <button class="btn btn-success btn-sm mr-2" v-b-modal="modalID">Enroll learner</button>
    </div>
    <b-modal title="Create New Module" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <div>
        <p>Upload a CSV file in the format shown below.:</p>
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Password</th>
              <th>Ref. ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Umaru Okoro</td>
              <td>umaru.okoro@example.com</td>
              <td>08040221144</td>
              <td>default</td>
              <td>12345</td>
            </tr>
            <tr>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          </tbody>
        </table>
        <!-- Uploader -->
        <VueFileAgent
          ref="vueFileAgent"
          :theme="'list'"
          :multiple="false"
          :deletable="true"
          :meta="true"
          accept=".csv"
          maxSize="50MB"
          :maxFiles="1"
          helpText="Please choose .csv file type."
          :errorText="{
            type: 'Invalid file type. .csv',
            size: 'File should not exceed 50MB in size.'
          }"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          @delete="fileDeleted($event)"
          v-model="fileRecords"
        ></VueFileAgent>
        <!--  -->
        <button class="btn btn-secondary mt-3" :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">Upload</button>
        <!--  -->
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AuthorEntrollUsers',
  props: {
    resourceUUID: { type: String, required: true },
    resourceType: { type: String, required: true }
  },
  data() {
    return {
      modalID: 'author-enroll-learners',
      // uploader
      fileRecords: [],
      fileRecordsForUpload: [] // maintain an upload queue
    }
  },
  methods: {
    filesSelected(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },

    async uploadFiles() {
      const uploadUrl = `${process.env.VUE_APP_API_URL}/author/entroll-users/${this.resourceUUID}/${this.resourceType}`
      const uploadHeaders = {
        'X-Test-Header': 'vue-file-agent',
        apikey: process.env.VUE_APP_API_KEY,
        Authorization: `Bearer ${this.authToken}`
      }
      console.log('this.fileRecordsForUpload:', this.fileRecordsForUpload)
      // Using the default uploader. You may use another uploader instead.
      let res = await this.$refs.vueFileAgent.upload(uploadUrl, uploadHeaders, this.fileRecordsForUpload)
      this.fileRecordsForUpload = []
      // if call upload succeed
      if (res[0].data.status == 'success') {
        window.location.reload()
      } else {
        this.$swal('Nope!', res[0].data.message, 'error') // upload failed
      }
    },

    onBeforeDelete(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        var k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
        }
      }
    },

    fileDeleted(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    }
  },
  computed: {
    authToken() {
      return this.$store.state.authToken
    }
  }
}
</script>

<style scoped>
th,
td {
  font-size: 0.9rem;
  font-family: 'Courier New', Courier, monospace;
}
</style>
