<template>
  <div class="container mt-n10">
    <div class="row">
      <div class="col-md-12">
        <transition name="custom-classes-transition" enter-active-class="animated bounceInLeft" leave-active-class="animated bounceOutRight">
          <div class="card bg-light mb-3" v-show="animate">
            <div class="card">
              <div class="card-header"><BackIcon></BackIcon> Course Info</div>
              <div class="card-body">
                <h2>{{ course.Name }}</h2>
                <img :src="course.Image" style="height: 300px" class="mb-4" />
                <table class="table">
                  <!-- <tr>
                    <th>Name</th>
                    <td>{{ course.Name }}</td>
                  </tr> -->

                  <tr>
                    <th>Introduction</th>
                    <td>{{ course.Introduction }}</td>
                  </tr>
                  <tr>
                    <th>Prerequisite</th>
                    <td v-html="course.Prerequisite"></td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td v-html="course.Description"></td>
                  </tr>
                  <tr>
                    <th>Completion status</th>
                    <td>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" :style="'width: ' + course.CompletetionPercentage + '%; background-color: #16994c'" :aria-valuenow="course.CompletetionPercentage" aria-valuemin="0" aria-valuemax="100">{{ course.CompletetionPercentage }}%</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="card-footer">
                <table>
                  <tr>
                    <td>
                      <router-link :to="{ name: 'CourseLessonsCandidate', params: { courseSlug: course.Slug, lessonUUID: course.LessonUUID } }" class="btn btn-danger btn-lg"> Go to class </router-link>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'CourseDetailsCandidate',

  data() {
    return {
      animate: true,
      show: false,
      hide: true,
      course: true
    }
  },

  mounted() {
    axios
      .get('/candidate/courses/' + this.$route.params.uuid)
      .then(res => {
        this.course = res.data.course
      })
      .catch(error => {})
  },

  methods: {
    copy() {
      this.show = true
      this.hide = false

      this.$copyText(this.course.uuid).then(
        function (e) {
          //alert("Copied");
          //console.log(e);
        },
        function (e) {
          //alert("Can not copy");
          //console.log(e);
        }
      )
    },

    deleteCourse(uuid) {
      axios.post('/authoring/courses/delete/' + uuid).then(res => {
        if (res.data.status === 'success') {
          this.$swal('Done!', res.data.message, 'success')
          this.$router.push({ name: 'CoursesAuthor' })
        } else {
          this.$swal('Done!', res.data.message, 'error')
        }
      })
    }
  },

  components: { BackIcon }
}
</script>

<style></style>
