import DashboardAuthor from '../views/author/DashboardAuthor.vue'

// course
import CoursesAuthor from '../views/author/course/CoursesAuthor.vue'
import CourseDetailsAuthor from '../views/author/course/CourseDetailsAuthor.vue'
import CourseCreateAuthor from '../views/author/course/CourseCreateAuthor.vue'
import CourseEditAuthor from '../views/author/course/CourseEditAuthor.vue'

// specialization
import SpecializationsAuthor from '../views/author/specialization/SpecializationsAuthor.vue'
import SpecializationDetailsAuthor from '../views/author/specialization/SpecializationDetailsAuthor.vue'
import SpecializationCreateAuthor from '../views/author/specialization/SpecializationCreateAuthor.vue'
import SpecializationEditAuthor from '../views/author/specialization/SpecializationEditAuthor.vue'

// Users
import ResourceStudentsAuthor from '../views/author/users/ResourceStudents.vue'

// lessons
import LessonsAuthor from '../views/author/lesson/LessonsAuthor.vue'
// question
import QuestionsAuthor from '../views/author/questions/QuestionsAuthor.vue'

// Test
import TestCreateAuthor from '../views/author/test/TestCreateAuthor.vue'
import TestDetailsAuthor from '../views/author/test/TestDetailsAuthor.vue'
import TestEditAuthor from '../views/author/test/TestEditAuthor.vue'
import TestsAuthor from '../views/author/test/TestsAuthor.vue'

// other
import ProfileAuthor from '../views/Profile.vue'

import ChangePasswordAuthor from '../views/ChangePassword.vue'
//import UserDetailsAuthor from '../views/admin/user/UserDetailsAuthor.vue'

import AuthorLayout from '../layouts/Author.vue'
import auth from '../helper/auth'

// user Author

const authorRoutes = [
  {
    path: '/author',
    component: AuthorLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'DashboardAuthor',
        component: DashboardAuthor
      },

      // Course
      {
        path: 'courses',
        name: 'CoursesAuthor',
        component: CoursesAuthor
      },
      {
        path: 'create-course',
        name: 'CourseCreateAuthor',
        component: CourseCreateAuthor
      },

      {
        path: 'course/:uuid',
        name: 'CourseDetailsAuthor',
        component: CourseDetailsAuthor
      },
      {
        path: 'edit-course/:uuid',
        name: 'CourseEditAuthor',
        component: CourseEditAuthor
      },

      // Specialization
      {
        path: 'specializations',
        name: 'SpecializationsAuthor',
        component: SpecializationsAuthor
      },
      {
        path: 'specialization-course',
        name: 'SpecializationCreateAuthor',
        component: SpecializationCreateAuthor
      },

      {
        path: 'specialization/:uuid',
        name: 'SpecializationDetailsAuthor',
        component: SpecializationDetailsAuthor
      },
      {
        path: 'edit-specialization/:uuid',
        name: 'SpecializationEditAuthor',
        component: SpecializationEditAuthor
      },

      // lessons
      {
        path: 'lessons/:courseUUID',
        name: 'LessonsAuthor',
        component: LessonsAuthor
      },

      // Tests
      {
        path: 'tests',
        name: 'TestsAuthor',
        component: TestsAuthor
      },
      {
        path: 'create-test',
        name: 'TestCreateAuthor',
        component: TestCreateAuthor
      },
      {
        path: 'test/:uuid',
        name: 'TestDetailsAuthor',
        component: TestDetailsAuthor
      },
      {
        path: 'edit-test/:uuid',
        name: 'TestEditAuthor',
        component: TestEditAuthor
      },

      // Questions
      {
        path: 'questions/:testUUID',
        name: 'QuestionsAuthor',
        component: QuestionsAuthor
      },

      // Users
      {
        path: 'students/:resourceUUID/:resourceType',
        name: 'ResourceStudentsAuthor',
        component: ResourceStudentsAuthor
      },

      // personal
      {
        path: 'profile',
        name: 'ProfileAuthor',
        component: ProfileAuthor
      },

      /* {
        path: 'user/:uuid',
        name: 'UserDetailsAuthor',
        component: UserDetailsAuthor
      }, */

      {
        path: 'change-password',
        name: 'ChangePasswordAuthor',
        component: ChangePasswordAuthor
      }
    ]
  }
]

export default authorRoutes
