<template>
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="card">
          <div class="card-header"><BackIcon></BackIcon> Create a bundle course</div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">
                      Name
                      <span class="text-danger">*</span>
                    </label>
                    <input v-model="specialization.name" type="text" class="form-control" :class="{ 'is-invalid': $v.specialization.name.$error, 'is-valid': !$v.specialization.name.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.specialization.name.required">Name is required.</span>
                      <span v-if="!$v.specialization.name.minLength">Name must be at least {{ $v.specialization.name.$params.minLength.min }} letters.</span>
                      <span v-if="!$v.specialization.name.maxLength">Name must be at most {{ $v.specialization.name.$params.maxLength.max }} letters.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="name">
                      Price
                      <span class="text-danger">*</span>
                    </label>
                    <input v-model="specialization.price" type="number" class="form-control" :class="{ 'is-invalid': $v.specialization.price.$error, 'is-valid': !$v.specialization.price.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.specialization.price.required">Price is required.</span>
                      <span v-if="!$v.specialization.price.numeric">Price must be numeric.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="introduction">
                      Introduction
                      <span class="text-danger">*</span>
                    </label>
                    <textarea v-model="specialization.introduction" class="form-control" :class="{ 'is-invalid': $v.specialization.introduction.$error, 'is-valid': !$v.specialization.introduction.$invalid }"></textarea>
                    <div class="invalid-feedback">
                      <span v-if="!$v.specialization.introduction.required">Introduction is required.</span>
                      <span v-if="!$v.specialization.introduction.minLength">Introduction must be at least {{ $v.specialization.introduction.$params.minLength.min }} letters.</span>
                      <span v-if="!$v.specialization.introduction.maxLength">Introduction must be at most {{ $v.specialization.introduction.$params.maxLength.max }} letters.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="prerequisite">
                      Prerequisite
                      <span class="text-danger">*</span>
                    </label>
                    <vue-editor v-model="specialization.prerequisite" :editorToolbar="editorToolbar" :class="{ 'is-invalid': $v.specialization.prerequisite.$error, 'is-valid': !$v.specialization.prerequisite.$invalid }"></vue-editor>
                    <input v-model="specialization.prerequisite" type="hidden" class="form-control" :class="{ 'is-invalid': $v.specialization.prerequisite.$error, 'is-valid': !$v.specialization.prerequisite.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.specialization.prerequisite.required">Prerequisite is required.</span>
                      <span v-if="!$v.specialization.prerequisite.minLength">Prerequisite must be at least {{ $v.specialization.prerequisite.$params.minLength.min }} letters.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="description">
                      Description
                      <span class="text-danger">*</span>
                    </label>
                    <vue-editor v-model="specialization.description" :editorToolbar="editorToolbar" :class="{ 'is-invalid': $v.specialization.description.$error, 'is-valid': !$v.specialization.description.$invalid }"></vue-editor>
                    <input v-model="specialization.description" type="hidden" class="form-control" placeholder="e.g. Mark" :class="{ 'is-invalid': $v.specialization.description.$error, 'is-valid': !$v.specialization.description.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.specialization.description.required">Description is required.</span>
                      <span v-if="!$v.specialization.description.minLength">Description must be at least {{ $v.specialization.description.$params.minLength.min }} letters.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <i class="fa fa-paperclip"></i> Specialization Artwork
                    <!-- <input type="file" ref="file" multiple="multiple" /> -->
                    <input type="file" ref="file" />

                    <p class="help-block"><b>File Type:</b> jpg,jpeg,png | <b>Max file size:</b> 2MB</p>
                  </div>
                  <!-- end col-md-12 -->
                </div>
              </div>

              <button :disabled="isDisabled" class="btn btn-danger" type="submit" id="submitButton">
                <i class="far fa-paper-plane"></i>
                Create
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { VueEditor } from "vue2-editor";
import BackIcon from '@/components/BackIcon.vue'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'SpecializationCreateAuthor',
  data() {
    return {
      specialization: {
        name: '',
        introduction: '',
        prerequisite: '',
        description: ''
      },
      submitted: false,
      isDisabled: false,
      animate: true,
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },
  validations: {
    specialization: {
      name: { required, minLength: minLength(5), maxLength: maxLength(150) },
      introduction: { required, minLength: minLength(25), maxLength: maxLength(300) },
      prerequisite: { required, minLength: minLength(25) },
      description: { required, minLength: minLength(25) },
      price: { required, numeric }
    }
  },
  components: {
    //VueEditor,
    BackIcon
  },
  computed: {},
  methods: {
    handleSubmit(e) {
      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isDisabled = false
        return
      }
      this.submitted = true
      this.isDisabled = true
      let formData = new FormData()
      let allowedExtensions = ['jpg', 'jpeg', 'png']
      if (this.$refs.file.files.length < 1) {
        this.submitted = false
        this.isDisabled = false
        this.$swal('Oops!', 'Specialization artwork is require. Please a image for your specialization.', 'error')
        return
      }
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i]
        formData.append('file', file)
        // Form Validate ...
        let fileName = file.name
        let fileExtension = fileName.replace(/^.*\./, '')
        if (allowedExtensions.includes(fileExtension) == false) {
          let msg = '.' + fileExtension + ' file not allowed. Please replace ' + file.name
          this.$swal('Oops!', msg, 'error')
          this.submitted = false
          this.isDisabled = false
          return
        }
        if (file.size > 2 * 1000 * 1000) {
          let msg = file.name + ' is larger than the allowed file size of 5MB.'
          this.$swal('Oops!', msg, 'error')
          this.submitted = false
          this.isDisabled = false
          return
        }
      }
      formData.append('name', this.specialization.name)
      formData.append('introduction', this.specialization.introduction)
      formData.append('prerequisite', this.specialization.prerequisite)
      formData.append('description', this.specialization.description)
      formData.append('price', this.specialization.price)

      axios
        .post('/author/specializations/create', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          this.submitted = false
          this.isDisabled = false
          if (res.data.status === 'success') {
            this.$v.$reset()
            this.specialization = {}

            this.$swal('Done!', res.data.message, 'success')
            this.$router.push({ name: 'SpecializationsAuthor' })
          } else {
            this.$swal('Nope!', res.data.message, 'error')
          }
        })
    }
  }
}
</script>

<style></style>
