<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">My Assessments</div>
            <div class="card-body-">
              <vue-good-table :columns="columns" :rows="tests" :search-options="searchOptions" :line-numbers="true" styleClass="vgt-table condensed myGoodTable">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'Name'">
                    <router-link :to="{ name: 'AssessmentDetailsCandidate', params: { uuid: props.row.UUID } }">
                      {{ props.row.Name }}
                    </router-link>
                  </span>
                  <span v-if="props.column.field == 'NoOfQuestionsToRender'">{{ props.row.NoOfQuestionsToRender }}</span>
                  <span v-if="props.column.field == 'TimeSet'">{{ props.row.TimeSet }}minutes</span>
                  <span v-if="props.column.field == 'TotalAttempt'">{{ props.row.TotalAttempt }}</span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AssessmentsCandidate',

  data() {
    return {
      tests: []
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get('/candidate/tests').then(res => {
      this.tests = res.data.tests
      loading.hide()
    })
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'Name'
        },
        {
          label: 'Questions',
          field: 'NoOfQuestionsToRender'
        },
        {
          label: 'Time',
          field: 'TimeSet'
        },
        {
          label: 'Attempts',
          field: 'TotalAttempt'
        }
      ]
      return cols
    },

    searchOptions() {
      return {
        enabled: true,
        trigger: 'enter'
      }
    },

    paginationOptions() {
      return {
        enabled: true,
        perPage: 20
      }
    }
  },

  methods: {}
}
</script>
