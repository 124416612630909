<template>
  <div>
    <button v-b-modal="modalID" class="float mb-5" title="Create new questions">
      <i class="icon icon-plus my-float"></i>
    </button>

    <b-modal :id="modalID" title="Create new question" size="lg" hide-footer no-close-on-backdrop>
      <!-- <p class="my-4">{{ testUUID }}</p> -->
      <b-overlay :show="showOverlay" rounded="sm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="questionContent">Content</label>
            <vue-editor id="question" v-model="questionContent" :editorToolbar="editorToolbar" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <div class="text-danger text-xs" v-show="$v.questionContent.$error">
              <span v-if="!$v.questionContent.required">Content is required.</span>
            </div>
          </div>

          <div class="form-group">
            <label for="questionFeedback">Feedback</label>
            <vue-editor id="feedback" v-model="questionFeedback" :editorToolbar="editorToolbar" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <!--  <div class="text-danger text-xs" v-show="$v.questionFeedback.$error">
            <span v-if="!$v.questionFeedback.required">Feedback is required.</span>
          </div> -->
          </div>

          <button class="btn btn-success btn-block btn-lg" type="submit">Submit</button>
        </form>

        <template #overlay>
          <div class="text-center">
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
            <p id="cancel-label">Please wait...</p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import editor from '../../../../helper/editor'
import { VueEditor } from 'vue2-editor'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    testUUID: String
  },

  data() {
    return {
      showOverlay: false,
      modalID: 'create-question',
      questionContent: '',
      questionFeedback: '',

      editorToolbar: editor.toolbar
    }
  },

  methods: {
    handleSubmit() {
      this.showOverlay = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showOverlay = false
        return
      }

      let formData = new FormData()
      formData.append('content', this.questionContent)
      formData.append('feedback', this.questionFeedback)
      axios.post(`/authoring/questions/create/${this.testUUID}`, formData).then(res => {
        if (res.data.status === 'success') {
          this.questionContent = ''
          this.questionFeedback = ''
          this.$emit('questionCreated', res.data.question)
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('', res.data.message, 'error')
        }
        this.showOverlay = false
      })
    },

    handleImageAdded: editor.handleImageAdded

    /* handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      //formData.append("image", file);
      formData.append("file", file);
      axios({
        url: "/authoring/questions-answers-image-upload",
        method: "POST",
        data: formData,
      })
        .then((result) => {
          console.log(result.data);

          //let url = result.data.url; // Get url from response
          let url = result.data.fileURL; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    }, */
  },

  validations: {
    questionContent: { required }
    //questionFeedback: { required },
  },

  components: {
    VueEditor
  }
}
</script>

<style scoped>
/* floating */

.float {
  position: fixed;
  width: 80px;
  height: auto;
  /*  height: 60px; */
  padding: 20px;
  bottom: 40px;
  right: 40px;
  background-color: #16994c;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  border: 1px solid transparent;
  outline: none;
  user-select: none;
}
.float:hover {
  background-color: #17af68;
  box-shadow: -2px -2px 3px #999;
  outline: none;
}

button:focus {
  outline: none;
}

.float:focus {
  outline: none;
  user-select: none;
  border: 1px solid transparent;
}
.my-float {
  /*  margin-top: 22px; */
  font-size: 25px;
}
</style>
