<template>
  <router-link v-if="$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }" style="color: #fff">
    <b-icon-arrow-left></b-icon-arrow-left>
  </router-link>
</template>

<script>
export default {
  name: "BackIcon",
};
</script>

<style scoped>
</style>