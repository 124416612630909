<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-10 offset-lg-1 col-sm-12 offset-sm-12">
        <div class="card mb-3">
          <div class="card-header">
            <!-- <b-icon-arrow-left style="cursor: pointer" onclick="history.back();"></b-icon-arrow-left> -->
            <BackIcon />
            <b-icon-person v-if="!$routerHistory.hasPrevious()"></b-icon-person>
            User Information
          </div>
          <div class="card-body">
            <table class="table table-striped table-responsive">
              <tr>
                <th>Name</th>
                <td>{{ user.name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{ user.phone }}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>{{ user.role.name }}</td>
              </tr>

              <tr v-if="user.role.slug == 'user'">
                <th>Organization</th>
                <td>{{ user.organization }}</td>
              </tr>

              <tr v-if="user.role.slug == 'second-level-support'">
                <th>Assigned Issue Category</th>
                <td>
                  <ul v-for="(cat, i) in user.categories" :key="i">
                    <li>{{ cat.name }}</li>
                  </ul>
                </td>
              </tr>
              <tr v-if="user.role.slug == 'agent'">
                <th>Assigned Issue Subcategory</th>
                <td>
                  <ul v-for="(cat, i) in user.subCategories" :key="i">
                    <li>{{ cat.name }}</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Tickets -->
    <div class="row">
      <div class="col-10 offset-1">
        <div class="card mb-3">
          <div class="card-header"><b-icon-stickies></b-icon-stickies> Tickets raised by {{ user.name }} {{ totalTickets }}</div>
          <div class="card-body">
            <div v-if="spinner" class="text-center">
              <b-spinner variant="warning" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END Tickets -->
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'

export default {
  components: { BackIcon },

  data() {
    return {
      user: { role: { name: '' } },
      totalTickets: 0,
      spinner: false,
      tickets: [],
      routeName: ''
    }
  },

  created() {
    //console.log(this.authUser.role);
    if (this.authUser.role == 'first-level-support') {
      this.routeName = 'TicketDetailsFirstLevel'
    } else if (this.authUser.role == 'second-level-support') {
      this.routeName = 'TicketDetailsSecondLevel'
    }

    let formData = new FormData()
    formData.append('uuid', this.$route.params.uuid)
    axios.post('/auth/user-details', formData).then(res => {
      if (res.data.status == 'success') {
        this.user = res.data.user
        this.tickets = res.data.tickets
        this.totalTickets = res.data.totalTickets
      } else {
        this.$swal('Ooops!', res.data.message, 'error')
      }
    })
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style></style>
