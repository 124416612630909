import DashboardAdmin from '../views/admin/Dashboard.vue'
import ProfileAdmin from '../views/Profile.vue'

import ChangePasswordAdmin from '../views/ChangePassword.vue'
import UserDetailsProfileAdmin from '../views/UserDetails.vue'

import AdminLayout from '../layouts/Admin.vue'
import auth from '../helper/auth'

// user admin
import UsersAdmin from '../views/admin/user/UsersAdmin.vue'
import CreateUserAdmin from '../views/admin/user/CreateUserAdmin.vue'
import EditUserAdmin from '../views/admin/user/EditUserAdmin.vue'
import UserDetailsAdmin from '../views/admin/user/UserDetailsAdmin.vue'

const adminRoutes = [
  {
    path: '/admin',
    component: AdminLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'DashboardAdmin',
        component: DashboardAdmin
      },

      {
        path: 'users-log',
        name: 'UsersLog'
        //component: () => import('../views/reports/UsersLog.vue')
      },

      /* User Admin */
      {
        path: 'users/:role',
        name: 'UsersAdmin',
        //component: () => import('../views/admin/user/UsersAdmin.vue')
        component: UsersAdmin
      },
      {
        path: 'user/create',
        name: 'CreateUserAdmin',
        //component: () => import('../views/admin/user/CreateUserAdmin.vue')
        component: CreateUserAdmin
      },
      {
        path: 'user/edit/:uuid',
        name: 'EditUserAdmin',
        //component: () => import('../views/admin/user/EditUserAdmin.vue')
        component: EditUserAdmin
      },
      {
        path: 'user/:uuid',
        name: 'UserDetailsProfileAdmin',
        //component: () => import('../views/admin/user/UserDetailsAdmin.vue')
        component: UserDetailsProfileAdmin
      },

      {
        path: 'profile',
        name: 'ProfileAdmin',
        component: ProfileAdmin
      },

      {
        path: 'user/:uuid',
        name: 'UserDetailsAdmin',
        component: UserDetailsAdmin
      },
      {
        path: 'change-password',
        name: 'ChangePasswordAdmin',
        component: ChangePasswordAdmin
      }
    ]
  }
]

export default adminRoutes
