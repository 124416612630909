<template>
  <div>
    <a href="#" v-b-modal="modalID">{{ buttomText }}</a>

    <b-modal :title="`Upload ${lesson.type}`" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <p>{{ lesson.name }}</p>
      <VueFileAgent
        ref="vueFileAgent"
        :theme="'list'"
        :multiple="false"
        :deletable="true"
        :meta="true"
        :accept="accept"
        :maxSize="allowFileSize"
        :maxFiles="1"
        :helpText="helpText"
        :errorText="{
          type: `Invalid file type. ${helpText}`,
          size: `File should not exceed ${allowFileSize} in size.`,
        }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
        v-model="fileRecords"
      ></VueFileAgent>
      <!-- <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">Upload {{ fileRecordsForUpload.length }} files</button> -->
      <button class="btn btn-secondary mt-3" :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">Upload {{ lesson.type }}</button>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    lesson: {},
    topicIndex: Number,
    lessonIndex: Number,
  },

  data() {
    return {
      modalID: "upload-" + this.lesson.uuid,
      // uploader
      fileRecords: [],
      uploadUrl: `${process.env.VUE_APP_API_URL}/authoring/uploads/${this.lesson.uuid}`,
      uploadHeaders: { "X-Test-Header": "vue-file-agent", apikey: process.env.VUE_APP_API_KEY },
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },

  methods: {
    async uploadFiles() {
      // Using the default uploader. You may use another uploader instead.
      let res = await this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
      this.fileRecordsForUpload = [];
      // if call upload succeed

      if (res[0].data.status == "success") {
        // emit back object for lessonFileUploaded function
        this.$emit("lessonFileUploaded", {
          topicIndex: this.topicIndex,
          lessonIndex: this.lessonIndex,
          newFileLink: res[0].data.newFileLink,
        });
        this.$swal("Done!", res[0].data.message, "success"); // notify user
        this.$bvModal.hide(this.modalID); // the the modal
      } else {
        this.$swal("Nope!", res[0].data.message, "error"); // upload failed
      }
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },

  computed: {
    buttomText() {
      if (this.lesson.file == "") {
        return "Upload";
      }
      return "Reupload";
    },
    allowFileSize() {
      return "300MB";
    },
    helpText() {
      if (this.lesson.type == "video") {
        return "Please choose .mp4 file type.";
      } else if (this.lesson.type == "pdf") {
        return "Please choose .pdf file type.";
      }
    },
    accept() {
      if (this.lesson.type == "video") {
        return ".mp4";
      } else if (this.lesson.type == "pdf") {
        return ".pdf";
      }
    },
  },
};
</script>

<style>
</style>