import axios from 'axios'
import store from '../store' // your vuex store
//import swal from 'sweetalert2'

let ifAuthenticated = (to, from, next) => {
  let formData = new FormData()
  formData.append('log_uuid', window.localStorage.getItem('log_uuid'))
  axios.post('/auth/refresh', formData).then(res => {
    if (res.data.status == 'success') {
      store.state.authToken = res.data.token
      store.state.authUser = res.data.user
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
      let pathArry = to.fullPath.split('/')

      if (pathArry[1] != store.state.authUser.role) {
        next(store.state.authUser.role)
        return
      }
      next()
      return
    } else {
      next('/login')
      alert(res.data.message)
      return
    }
  })
}

let ifNotAuthenticated = (to, from, next) => {
  let formData = new FormData()
  formData.append('log_uuid', window.localStorage.getItem('log_uuid'))
  axios.post('/auth/refresh', formData).then(res => {
    if (res.data.status == 'success') {
      store.state.authToken = res.data.token
      store.state.authUser = res.data.user
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
      // handle for other roles
      let pathArry = to.fullPath.split('/')
      if (pathArry[1] != store.state.authUser.role) {
        next(store.state.authUser.role)
        return
      }
      next()
      return
    } else {
      next()
      return
    }
  })
}
/* let ifNotAuthenticated = (to, from, next) => {
    let authToken = store.state.authToken;
    if (authToken == "" || authToken == null || authToken == undefined) {
        next()
        return
    }
    next(store.state.authUser.role)
} */

export default {
  ifAuthenticated,
  ifNotAuthenticated
}
