<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">My Courses</div>
          <div class="card-body">
            <div class="row">
              <!-- courses -->
              <div class="col-md-4" v-for="(course, index) in courses" :key="index">
                <!-- My course -->
                <div class="course-block">
                  <!-- <router-link :to="{ name: 'CourseLessonsCandidate', params: { courseSlug: course.Slug, lessonUUID: course.LessonUUID } }"> -->
                  <router-link :to="{ name: 'CourseDetailsCandidate', params: { uuid: course.UUID } }">
                    <div class="course-img">
                      <img :src="course.Image" alt="" class="img-fluid" />
                    </div>

                    <div class="course-content">
                      <div class="course-meta">
                        <span class="course-student"><i class="icon-time"></i>{{ course.EnrolledAt | formatDate }}</span>
                        <span class="course-duration"><i class="icon-files"></i>{{ course.TotalLectures }} Lectures</span>
                      </div>
                      <!-- <span class="course-cat">progress</span> -->
                      <h4>{{ course.Name }}</h4>
                      <!-- <p>{{ course.Introduction }}</p> -->
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" :style="'width: ' + course.CompletetionPercentage + '%; background-color: #16994c'" :aria-valuenow="course.CompletetionPercentage" aria-valuemin="0" aria-valuemax="100">{{ course.CompletetionPercentage }}%</div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <!--  end My course -->
              </div>
            </div>
            <!-- courses -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CoursesCandidate',

  data() {
    return {
      courses: []
    }
  },

  mounted() {
    axios.get('/candidate/courses').then(res => {
      this.courses = res.data.courses
    })
  }
}
</script>

<style></style>
