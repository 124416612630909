<template>
  <div>
    <button v-b-modal="modalID" class="btn btn-sm btn-outline-secondary mr-2">Edit Question {{ questionIndex + 1 }}</button>

    <b-modal :id="modalID" :title="'Edit question' + (questionIndex + 1)" size="lg" hide-footer no-close-on-backdrop>
      <!-- <p class="my-4">{{ testUUID }}</p> -->
      <b-overlay :show="showOverlay" rounded="sm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="questionContent">Content</label>
            <vue-editor v-model="questionContent" :editorToolbar="editorToolbar" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <div class="text-danger text-xs" v-show="$v.questionContent.$error">
              <span v-if="!$v.questionContent.required">Content is required.</span>
            </div>
          </div>

          <div class="form-group">
            <label for="questionFeedback">Feedback</label>
            <vue-editor v-model="questionFeedback" :editorToolbar="editorToolbar" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <!--  <div class="text-danger text-xs" v-show="$v.questionFeedback.$error">
            <span v-if="!$v.questionFeedback.required">Feedback is required.</span>
          </div> -->
          </div>

          <button class="btn btn-success btn-block btn-lg" type="submit">Submit</button>
        </form>

        <template #overlay>
          <div class="text-center">
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
            <p id="cancel-label">Please wait...</p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import editor from '../../../../helper/editor'
import { VueEditor } from 'vue2-editor'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    question: Object,
    questionIndex: Number
  },

  data() {
    return {
      showOverlay: false,
      modalID: 'edit-question-' + this.question.uuid,
      questionContent: this.question.content,
      questionFeedback: this.question.feedback,

      editorToolbar: editor.toolbar
    }
  },

  methods: {
    handleSubmit() {
      this.showOverlay = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showOverlay = false
        return
      }

      let formData = new FormData()
      formData.append('content', this.questionContent)
      formData.append('feedback', this.questionFeedback)
      axios.post(`/authoring/questions/edit/${this.question.uuid}`, formData).then(res => {
        if (res.data.status === 'success') {
          this.$emit('questionEdited', { question: res.data.question, questionIndex: this.questionIndex })
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('', res.data.message, 'error')
        }
        this.showOverlay = false
      })
    },

    handleImageAdded: editor.handleImageAdded
  },

  validations: {
    questionContent: { required }
    //questionFeedback: { required },
  },

  components: {
    VueEditor
  }
}
</script>

<style scoped>
/* .btn-sm {
  text-transform: uppercase;
  font-size: 11px;
}
.btn-outline-secondary {
  border-color: #d9dfe4;
} */
</style>
