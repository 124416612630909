<template>
  <div class="container-fluid">
    <!-- <div class="row mb-5">
      <div class="col-sm-8">
        <h3 class="m-0 text-dark">
          {{ test.name }}
        </h3>
        <h6>QUESTIONS:</h6>
      </div>
      <div class="col-sm-4">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'DashboardAuthor' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'TestsAuthor' }">Tests</router-link>
          </li>
          <li class="breadcrumb-item active">Questions</li>
        </ol>
      </div>
    </div> -->

    <!-- Lessons modules -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><BackIcon /> {{ test.name }}</div>
          <div class="card-body">
            <!-- Questions lIst -->
            <div>
              <div class="accordion" role="tablist">
                <div class="card myCard2 mb-1" v-for="(question, i) in questions" :key="i">
                  <div class="card-header" v-b-toggle="'accordion-' + i">Question {{ i + 1 }}</div>
                  <b-collapse :id="'accordion-' + i" accordion="my-accordion" role="tabpanel">
                    <div class="card-body">
                      <div class="questionBlock">
                        <div class="question">
                          <h6>Question {{ i + 1 }} content:</h6>
                          <div v-html="question.content"></div>
                        </div>
                        <div class="feedback">
                          <h6>Question {{ i + 1 }} feedback:</h6>
                          <div v-html="question.feedback"></div>
                        </div>
                        <!-- <button class="btn btn-sm btn-outline-secondary mr-2">Delete Question {{ i + 1 }}</button> -->
                        <!-- <button class="btn btn-sm btn-outline-secondary mr-2">Edit Question {{ i + 1 }}</button> -->

                        <table>
                          <tr>
                            <td>
                              <EditQuestion :question="question" :questionIndex="i" @questionEdited="questionEdited" />
                            </td>
                            <td>
                              <DeleteQuestion :questionUUID="question.uuid" :questionIndex="i" @questionDeleted="questionDeleted" />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="card-footer" v-for="(answer, j) in question.answers" :key="j">
                      <div class="answerBlock">
                        <div class="mark" @click="changeMark(answer, i, j)">
                          <!-- <i class="icon icon-circle1"></i> -->
                          <i class="icon icon-check-circle1 text-success" v-show="answer.mark > 0"></i>
                          <i class="icon icon-times-circle1 text-danger" v-show="answer.mark == 0"></i>
                        </div>
                        <div style="margin-left: 10px">
                          <div class="answer">
                            <h6>Answer {{ j + 1 }} content:</h6>
                            <div v-html="answer.content"></div>
                          </div>
                          <div class="feedback">
                            <h6>Answer {{ j + 1 }} feedback:</h6>
                            <div v-html="answer.feedback"></div>
                          </div>

                          <table>
                            <tr>
                              <td>
                                <EditAnswer :answer="answer" :answerIndex="j" :questionIndex="i" @answerEdited="answerEdited" />
                              </td>
                              <td>
                                <DeleteAnswer :answerUUID="answer.uuid" :answerIndex="j" :questionIndex="i" @answerDeleted="answerDeleted" />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <CreateAnswer :questionUUID="question.uuid" :questionIndex="i" @answerCreated="answerCreated" />
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
            <!--  End questions list -->
          </div>
        </div>
      </div>
    </div>
    <!-- floating button -->
    <CreateQuestion :testUUID="test.uuid" @questionCreated="questionCreated" />
    <!-- floating button -->
  </div>
</template>

<script>
import axios from 'axios'
import EditQuestion from './components/EditQuestion'
import CreateQuestion from './components/CreateQuestion'
import DeleteQuestion from './components/DeleteQuestion'
// Answers
import EditAnswer from './components/EditAnswer'
import CreateAnswer from './components/CreateAnswer'
import DeleteAnswer from './components/DeleteAnswer'

import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'QuestionsAuthor',

  data() {
    return {
      test: {},
      questions: []
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios
      .get('/authoring/questions/' + this.$route.params.testUUID)
      .then(res => {
        this.test = res.data.test
        this.questions = res.data.questions
        loading.hide()
      })
      .catch(error => {
        loading.hide()
      })
  },

  methods: {
    questionCreated(data) {
      this.questions.push(data)
    },
    questionEdited(data) {
      this.questions[data.questionIndex].content = data.question.content
      this.questions[data.questionIndex].feedback = data.question.feedback
    },

    questionDeleted(index) {
      this.questions.splice(index, 1)
    },

    // Answers
    answerCreated(data) {
      this.questions[data.questionIndex].answers.push(data.answer)
    },

    answerEdited(data) {
      this.questions[data.questionIndex].answers[data.answerIndex].content = data.answer.content
      this.questions[data.questionIndex].answers[data.answerIndex].feedback = data.answer.feedback
    },

    answerDeleted(data) {
      this.questions[data.questionIndex].answers.splice(data.answerIndex, 1)
    },

    changeMark(answer, questionIndex, answerIndex) {
      let mark = 0
      if (answer.mark == 0) {
        mark = 1
      }
      axios.post(`/authoring/answers/mark/${answer.uuid}/${mark}`).then(res => {
        if (res.data.status == 'success') {
          this.questions[questionIndex].answers[answerIndex].mark = res.data.answer.mark
        } else {
          this.$swal('', res.data.message, 'error')
        }
      })
    }
  },

  components: {
    CreateQuestion,
    DeleteQuestion,
    EditQuestion,
    // Answers
    CreateAnswer,
    DeleteAnswer,
    EditAnswer,
    BackIcon
  }
}
</script>

<style scoped>
.myCard2 .card-header {
  background-color: #565966;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.myCard2 .card-header:hover {
  background-color: #4c4e5a;
}
.questionBlock {
  width: 100%;
  min-height: 100px;
}
.question,
.answer {
  width: 100%;
  background-color: #f1e3e0;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.feedback {
  width: 100%;
  background-color: #e3e5f0;
  padding: 20px;
  margin-top: 1px;
  margin-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.question h6,
.answer h6,
.feedback h6 {
  text-transform: uppercase;
  font-size: 11px;
  font-style: italic;
}

.answerBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mark {
  cursor: pointer;
}
.mark i {
  font-size: 25px;
}
</style>
