<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-sm-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-journal-text v-if="!$routerHistory.hasPrevious()"></b-icon-journal-text> Register</div>
          <div class="card-body">
            <form @submit.prevent="signup">
              <div class="form-group">
                <label for="name">Name</label>

                <input type="text" v-model="user.name" class="form-control" placeholder :class="{ 'is-invalid': $v.user.name.$error, 'is-valid': !$v.user.name.$invalid }" />
                <div class="invalid-feedback">
                  <span v-if="!$v.user.name.required">Name is required.</span>
                  <span v-if="!$v.user.name.minLength">Name must be at least {{ $v.user.name.$params.minLength.min }} letters.</span>
                  <span v-if="!$v.user.name.maxLength">Name must be at most {{ $v.user.name.$params.maxLength.max }} letters.</span>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="name">Email</label>
                    <input v-model="user.email" type="email" class="form-control" placeholder :class="{ 'is-invalid': $v.user.email.$error, 'is-valid': !$v.user.email.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.user.email.required">Email is required.</span>
                      <span v-if="!$v.user.email.email">Email must be valid email address.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="phone">Phone number</label>
                    <input type="text" v-model="user.phone" class="form-control" placeholder :class="{ 'is-invalid': $v.user.phone.$error, 'is-valid': !$v.user.phone.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.user.phone.required">Phone is required.</span>
                      <span v-if="!$v.user.phone.numeric">Phone must be numeric.</span>
                      <span v-if="!$v.user.phone.minLength">Phone must be at least {{ $v.user.phone.$params.minLength.min }} digits.</span>
                      <span v-if="!$v.user.phone.maxLength">Phone must be at most {{ $v.user.phone.$params.maxLength.max }} digits.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label>Password</label>
                    <input v-model="user.newPassword" type="password" placeholder="password" class="form-control" :class="{ 'is-invalid': $v.user.newPassword.$error, 'is-valid': !$v.user.newPassword.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.user.newPassword.required">Password is required.</span>
                      <span v-if="!$v.user.newPassword.minLength">Password must be at least {{ $v.user.newPassword.$params.minLength.min }} characters.</span>
                    </div>
                    <span v-if="!strong_password" class="text-danger" style="font-size: 0.7rem">Password must contain upper case, lower case, number and special character.</span>
                  </div>
                </div>
                <!--  confirm -->
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label>Confirm Password</label>
                    <input v-model="user.confirmPassword" type="password" placeholder="confirm password" class="form-control" :class="{ 'is-invalid': $v.user.confirmPassword.$error, 'is-valid': !$v.user.confirmPassword.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.user.confirmPassword.required">Confirm password is required.</span>
                      <span v-if="!$v.user.confirmPassword.sameAs">Passwords must be identical.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="phone">Organization</label>
                <input type="text" v-model="user.organization" class="form-control" placeholder />
              </div>

              <div class="row">
                <div class="col-6">
                  <button v-if="!spinner" type="submit" class="btn btn-warning">Submit</button>
                  <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
                </div>
                <div class="col-6 text-right">
                  <span style="font-size: 12px">Already have an account?</span>
                  <br />
                  <router-link :to="{ name: 'Login' }" class="text-sm">Sign in</router-link>
                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, email, numeric, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'Register',
  components: { BackIcon },
  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: '',
        organization: '',
        newPassword: '',
        confirmPassword: ''
      },
      spinner: false,

      /*  contains_number: false,
      contains_uppercase: false,
      contains_special_character: false, */
      strong_password: true
    }
  },

  methods: {
    signup() {
      this.spinner = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
      let contains_number = /\d/.test(this.user.newPassword)
      let contains_uppercase = /[A-Z]/.test(this.user.newPassword)
      let contains_special_character = format.test(this.user.newPassword)

      //console.log(contains_number);
      //console.log(contains_uppercase);
      // console.log(contains_special_character);

      if (contains_number === false || contains_uppercase === false || contains_special_character === false) {
        this.strong_password = false
        this.spinner = false
        return
      }
      //console.log('pass validator')
      //return;

      let form = new FormData()
      form.append('name', this.user.name)
      form.append('email', this.user.email)
      form.append('phone', this.user.phone)
      form.append('organization', this.user.organization)
      form.append('newPassword', this.user.newPassword)
      form.append('originURL', window.location.origin)

      axios
        .post('/auth/register', form)
        .then(res => {
          if (res.data.status == 'success') {
            this.$swal('', res.data.message, 'success')
            this.user = {}
            this.$v.$reset()
          } else {
            this.$swal('', res.data.message, 'error')
          }
          this.spinner = false
          this.strong_password = true
        })
        .catch(err => {
          //console.log(err)
          this.$swal('', 'Unable to send request.', 'error')
          this.spinner = false
          this.strong_password = true
        })
    }

    /* checkPassword() {
      console.log("Check");
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      this.contains_number = /\d/.test(this.user.newPassword);
      this.contains_uppercase = /[A-Z]/.test(this.user.newPassword);
      this.contains_special_character = format.test(this.user.newPassword);

      if (this.contains_special_character === true && this.contains_uppercase === true && this.contains_number === true) {
        this.strong_password = true;
      } else {
        this.strong_password = false;
      }
    }, */
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  validations: {
    user: {
      name: { required, minLength: minLength(2), maxLength: maxLength(30) },
      email: { required, email },
      phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) },
      newPassword: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAs: sameAs('newPassword') }
    }
  }
}
</script>
