<template>
  <div class="container-fluid" id="UsersAdmin">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">
          {{ roleObj.name }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            {{ roleObj.name }}
          </div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="users"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                trigger: 'enter',
              }"
              :pagination-options="{
                enabled: true,
                perPage: 50,
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">
                  <router-link :to="{ name: 'UserDetailsAdmin', params: { uuid: props.row.uuid } }">
                    <b>{{ props.row.name }}</b>
                  </router-link>
                </span>

                <div v-if="props.row.role.slug == 'user'">
                  <span v-if="props.column.field == 'matric'">{{ props.row.matric }}</span>
                </div>

                <span v-if="props.column.field == 'email'">{{ props.row.email }}</span>
                <span v-if="props.column.field == 'phone'">{{ props.row.phone }}</span>

                <span v-if="props.column.field == 'updatedAt'">{{ props.row.updatedAt | formatDateTime }}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UsersAdmin",

  data() {
    return {
      users: [
        {
          role: { name: "" },
          categories: [{ name: "" }],
          subCategories: [{ name: "" }],
        },
      ],

      roleObj: {},
    };
  },

  watch: {
    "$route.params.role": function(role) {
      this.loadUsers(role);
      window.scrollTo(0, 0);
    },
  },

  mounted() {
    /*  let role = "agent";
    if (this.$route.params.role != undefined) {
      role = this.$route.params.role;
    } */
    this.loadUsers(this.$route.params.role);
  },

  methods: {
    loadUsers(role) {
      let loading = this.$loading.show();
      axios.get(`/admin/users?role=${role}`).then((res) => {
        if (res.data.status == "success") {
          this.users = res.data.users;
          this.roleObj = res.data.role;
        }
        loading.hide();
      });
    },
  },

  computed: {
    columns() {
      /* let roleLable = "";
      if (this.role == "agent") {
        roleLable = this.client.topCategoryLabel;
      } else if (this.role == "supervisor") {
        roleLable = this.client.subCategoryLabel;
      } */
      let cols = [
        {
          label: "Name",
          field: "name",
        },

        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Date/Time",
          field: "updatedAt",
        },
      ];
      return cols;
    },

    client() {
      return this.$store.state.client;
    },
  },
};
</script>
