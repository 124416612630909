<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <div class="alert" role="alert" style="color: #721c24; background-color: #f8d7da; border-color: #f5c6cb"><b>NOTE:</b> Ongoing assessment questions update...</div> -->
        <div class="card mb-3">
          <div class="card-header">Login</div>
          <div class="card-body" style="background-color: #fff">
            <div class="row">
              <div class="col-md-6">
                <div class="jumbotron">
                  <form @submit.prevent="submit">
                    <div class="form-group mb-3">
                      <label for="email">Email</label>
                      <input v-model="user.email" type="text" class="form-control" placeholder="Email" :class="{ 'is-invalid': $v.user.email.$error, 'is-valid': !$v.user.email.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.user.email.required">Email is required.</span>
                      </div>
                    </div>
                    <div class="form-group mb-3">
                      <label for="email">Password</label>
                      <input v-model="user.password" type="password" class="form-control" placeholder="Password" :class="{ 'is-invalid': $v.user.password.$error, 'is-valid': !$v.user.password.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.user.password.required">Password is required.</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button v-if="!spinner" type="submit" class="btn btn-warning">Login</button>
                        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
                      </div>
                      <div class="col-6 text-right">
                        <!--  <router-link :to="{ name: 'Register' }">Sign up</router-link>
                  <br /> -->
                        <router-link to="/password-reset">Forgot Password?</router-link>
                        <br />
                      </div>
                      <!-- /.col -->
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-6">
                <div class="noticeboard">
                  <!--  <h5 class="text-danger">NOTICE</h5> -->
                  <b>I CAN NOT LOG IN?</b>
                  <ul>
                    <li>Step 1: Ensure your email address is correctly inputted, with no space(s).</li>
                    <li>Step 2: Type in your password (profile code) correctly.</li>
                    <li>Step 3: Click Login.</li>
                  </ul>
                  <b> I CAN NOT REMEMBER MY PASSWORD?</b>
                  <ul>
                    <li>Step 1: Click on Forget Password?</li>
                    <li>Step 2: Type your correct email address.</li>
                    <li>Step 3: Click Send Password Reset Link</li>
                  </ul>
                  <p>A link would be sent to your email address, click the link and follow prompts.</p>

                  <b>I CAN NOT VIEW THE LECTURE?</b>
                  <ul>
                    <li>Step 1:Do a hard reset on your browser.</li>
                    <li>Step 2: Re-Login.</li>
                  </ul>
                  For hard reset on Google Chrome; Hold Ctrl and F5, or Hold Ctrl + Shift and Click R.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Login',

  components: {},

  data() {
    return {
      spinner: false,
      user: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    submit() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }
      let formData = new FormData()
      formData.append('email', this.user.email)
      formData.append('password', this.user.password)
      axios
        .post('/auth', formData)
        .then(res => {
          if (res.data.status == 'success') {
            this.$store.state.authUser = res.data.user
            this.$store.state.authToken = res.data.token
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
            window.localStorage.setItem('log_uuid', res.data.log) // this is auth log uuid for server to retrieve JWT token
            // handle for other roles
            this.$router.push(res.data.user.role)
            return
          } else {
            this.$store.state.authUser = {}
            this.$swal('', res.data.message, 'error')
          }
          this.spinner = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  },

  validations: {
    user: {
      email: { required },
      password: { required }
    }
  }
}
</script>

<style scoped>
.noticeboard {
  font-size: 0.8rem;
}
.noticeboard b {
  color: #16994c;
}
.jumbotron {
  background-color: #ecf0f5;
}
.noticeboard ul {
  margin-left: 1rem;
}
</style>
