<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">Assessments</div>
          <div class="card-body-">
            <vue-good-table :columns="columns" :rows="tests" :search-options="searchOptions" :pagination-options="paginationOptions" styleClass="vgt-table condensed myGoodTable" :line-numbers="true">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'Name'">
                  <router-link :to="{ name: 'TestDetailsAuthor', params: { uuid: props.row.UUID } }">
                    <b>{{ props.row.Name }}</b>
                  </router-link>
                </span>
                <span v-if="props.column.field == 'TotalQuestions'">{{ props.row.NoOfQuestionsToRender }}</span>
                <span v-if="props.column.field == 'TimeSet'">{{ props.row.TimeSet }}</span>
                <span v-if="props.column.field == 'Type'">{{ props.row.Type }}</span>
                <span v-if="props.column.field == 'CreatedAt'">{{ props.row.CreatedAt | formatDate }}</span>

                <span v-if="props.column.field == 'Actions'">
                  <b-dropdown id="dropdown-1" text="Actions" class="btn-xs" size="xs">
                    <b-dropdown-item :to="{ name: 'TestEditAuthor', params: { uuid: props.row.UUID } }">Edit</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'TestDetailsAuthor', params: { uuid: props.row.UUID } }">Details</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'QuestionsAuthor', params: { testUUID: props.row.UUID } }">Questions</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'ResourceStudentsAuthor', params: { resourceUUID: props.row.UUID, resourceType: 'tests' } }">Students</b-dropdown-item>
                  </b-dropdown>
                </span>
              </template>
              <div slot="table-actions">
                <router-link :to="{ name: 'TestCreateAuthor' }" class="btn btn-success btn-sm mr-2">Create</router-link>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'TestsAuthor',

  data() {
    return {
      tests: []
    }
  },

  beforeMount() {
    axios
      .get('/authoring/my-tests')
      .then(res => {
        this.tests = res.data.tests
      })
      .catch(error => {})
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'Name'
        },
        {
          label: 'Total Questions',
          field: 'TotalQuestions'
        },
        {
          label: 'Time Set',
          field: 'TimeSet'
        },
        {
          label: 'Type',
          field: 'Type'
        },

        {
          label: 'Data/Time',
          field: 'CreatedAt'
        },

        {
          label: '',
          field: 'Actions',
          sortable: false
        }
      ]
      return cols
    },

    searchOptions() {
      return {
        enabled: true,
        trigger: 'enter'
      }
    },

    paginationOptions() {
      return {
        enabled: true,
        perPage: 20
      }
    }
  },

  components: {
    VueGoodTable
  }
}
</script>
