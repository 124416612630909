<template>
  <main>
    <div class="container mt-5">
      <div class="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4">
        <div class="mr-4 mb-3 mb-sm-0">
          <h1 class="mb-0">Dashboard</h1>
          <div class="small">
            <span class="font-weight-500 text-primary">{{ dayOfTheWeek }}</span> {{ customDate }}
            <!-- PM -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-4 mb-4">
          <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-purple h-100">
            <div class="card-body" style="cursor: pointer" @click="box('CoursesAuthor')">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="small font-weight-bold text-purple mb-1"><b>Courses</b></div>
                  <div class="h5">{{ dashboard.courses }}</div>
                </div>
                <div class="ml-2">
                  <i class="icon icon-book text-gray-200" style="font-size: 50px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-xl-3 col-md-4 mb-4">
          <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-blue h-100">
            <div class="card-body" style="cursor: pointer" @click="box('SpecializationsAuthor')">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="small font-weight-bold text-blue mb-1"><b>Bundle Courses</b></div>
                  <div class="h5">{{ dashboard.specializations }}</div>
                </div>
                <div class="ml-2">
                  <i class="icon icon-folder text-gray-200" style="font-size: 50px"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="col-xl-3 col-md-4 mb-4">
          <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-green h-100">
            <div class="card-body" style="cursor: pointer" @click="box('TestsAuthor')">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="small font-weight-bold text-green mb-1"><b>Assessments</b></div>
                  <div class="h5">{{ dashboard.tests }}</div>
                </div>
                <div class="ml-2">
                  <i class="icon icon-files text-gray-200" style="font-size: 40px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
/* temporarily use for redirect author to /author/courses */
export default {
  data() {
    return {
      dashboard: {}
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios
      .get('/author/dashboard')
      .then(res => {
        this.dashboard = res.data
        loading.hide()
      })
      .catch(error => {
        loading.hide()
      })
  },

  methods: {
    box(path) {
      this.$router.push({ name: path })
    }
  },

  computed: {
    dayOfTheWeek() {
      var weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      var d = new Date()
      return weekday[d.getDay()]
    },
    customDate() {
      //· September 20, 2020 · 12:16 PM
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const d = new Date()
      let ampm = 'AM'
      let hour = d.getHours()
      if (d.getHours() > 12) {
        ampm = 'PM'
        hour = hour - 12
      }
      const time = `${hour}:${d.getMinutes()} ${ampm}`
      const data = `· ${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} · ${time}`
      return data
    }
  }
}
</script>

<style scoped>
.card:hover {
  margin-top: -5px;
}
.flex-grow-1 div.h5 {
  font-size: 1.8rem;
}
.flex-grow-1 div.small {
  font-weight: bold;
}
</style>
