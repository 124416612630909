import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//axios
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['apikey'] = process.env.VUE_APP_API_KEY
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/general.css'
import './assets/admin.css'
import './assets/timeline.css'
import './assets/editor.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Sweetalert2 // 12kb + 626 KB
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css' // 41 KB
Vue.use(VueSweetalert2)

// Validate 132kb
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// VueGoodTablePlugin ..
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin)

// vue2Dropzone
//import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

// vue-loading-overlay // 12kb
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css' // 503 bytes
Vue.use(Loading, {
  color: '#16994C',
  loader: 'dots',
  canCancel: true
})

// vue-progressbar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#16994C',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

// VuePlyr
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true }
    //debug: true,
  },
  emit: ['ended']
})

// VueCountdown
import VueCountdown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountdown.name, VueCountdown)

//StarRating
import StarRating from 'vue-star-rating'
Vue.component('StarRating', StarRating)

import Vue2Editor from 'vue2-editor'
Vue.use(Vue2Editor)

// vue-file-agent ...
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css' //import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent)

// filter
import './helper/filters'
import './assets/font-icons.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
