<template>
  <div class="container mt-n10">
    <div class="row">
      <div class="col-md-12">
        <transition name="custom-classes-transition" enter-active-class="animated bounceInLeft" leave-active-class="animated bounceOutRight">
          <div class="card bg-light mb-3" v-show="animate">
            <div class="card">
              <div class="card-header">
                <BackIcon></BackIcon> Edit Course: <small>{{ course.name }}</small>
              </div>
              <div class="card-body">
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">
                          Name
                          <span class="text-danger">*</span>
                        </label>
                        <input v-model="course.name" type="text" class="form-control" :class="{ 'is-invalid': $v.course.name.$error, 'is-valid': !$v.course.name.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.name.required">Name is required.</span>
                          <span v-if="!$v.course.name.minLength">Name must be at least {{ $v.course.name.$params.minLength.min }} letters.</span>
                          <span v-if="!$v.course.name.maxLength">Name must be at most {{ $v.course.name.$params.maxLength.max }} letters.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="name">
                          Price
                          <span class="text-danger">*</span>
                        </label>
                        <input v-model="course.price" type="number" class="form-control" :class="{ 'is-invalid': $v.course.price.$error, 'is-valid': !$v.course.price.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.price.required">Price is required.</span>
                          <span v-if="!$v.course.price.numeric">Price must be numeric.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="introduction">
                          Introduction
                          <span class="text-danger">*</span>
                        </label>
                        <textarea v-model="course.introduction" class="form-control" :class="{ 'is-invalid': $v.course.introduction.$error, 'is-valid': !$v.course.introduction.$invalid }"></textarea>
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.introduction.required">Introduction is required.</span>
                          <span v-if="!$v.course.introduction.minLength">Introduction must be at least {{ $v.course.introduction.$params.minLength.min }} letters.</span>
                          <span v-if="!$v.course.introduction.maxLength">Introduction must be at most {{ $v.course.introduction.$params.maxLength.max }} letters.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="prerequisite">
                          Prerequisite
                          <span class="text-danger">*</span>
                        </label>
                        <vue-editor v-model="course.prerequisite" :editorToolbar="editorToolbar" :class="{ 'is-invalid': $v.course.prerequisite.$error, 'is-valid': !$v.course.prerequisite.$invalid }"></vue-editor>
                        <input v-model="course.prerequisite" type="hidden" class="form-control" :class="{ 'is-invalid': $v.course.prerequisite.$error, 'is-valid': !$v.course.prerequisite.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.prerequisite.required">Prerequisite is required.</span>
                          <span v-if="!$v.course.prerequisite.minLength">Prerequisite must be at least {{ $v.course.prerequisite.$params.minLength.min }} letters.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="description">
                          Description
                          <span class="text-danger">*</span>
                        </label>
                        <vue-editor v-model="course.description" :editorToolbar="editorToolbar" :class="{ 'is-invalid': $v.course.description.$error, 'is-valid': !$v.course.description.$invalid }"></vue-editor>
                        <input v-model="course.description" type="hidden" class="form-control" placeholder="e.g. Mark" :class="{ 'is-invalid': $v.course.description.$error, 'is-valid': !$v.course.description.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.description.required">Description is required.</span>
                          <span v-if="!$v.course.description.minLength">Description must be at least {{ $v.course.description.$params.minLength.min }} letters.</span>
                        </div>
                      </div>
                      <!-- end col-md-12 -->
                      <div class="form-group">
                        <i class="fa fa-paperclip"></i> Course Artwork
                        <!-- <input type="file" ref="file" multiple="multiple" /> -->
                        <input type="file" ref="file" />

                        <p class="help-block"><b>File Type:</b> jpg,jpeg,png | <b>Max file size:</b> 2MB</p>
                      </div>
                    </div>
                  </div>

                  <button :disabled="isDisabled" class="btn btn-danger" type="submit">
                    <i class="far fa-paper-plane"></i>
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { VueEditor } from "vue2-editor";
import BackIcon from '@/components/BackIcon.vue'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'CourseEditAuthor',

  mounted() {
    axios
      .get('/author/courses/' + this.$route.params.uuid)
      .then(res => {
        this.course = res.data.course
      })
      .catch(error => {})
  },

  data() {
    return {
      course: {
        name: '',
        introduction: '',
        prerequisite: '',
        description: ''
      },
      submitted: false,
      isDisabled: false,
      animate: true,
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  validations: {
    course: {
      name: { required, minLength: minLength(5), maxLength: maxLength(150) },
      introduction: { required, minLength: minLength(25), maxLength: maxLength(300) },
      prerequisite: { required, minLength: minLength(25) },
      description: { required, minLength: minLength(25) },
      price: { required, numeric }
    }
  },

  components: {
    //VueEditor,
    BackIcon
  },

  methods: {
    handleSubmit(e) {
      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isDisabled = false
        return
      }
      this.submitted = true
      this.isDisabled = true
      let formData = new FormData()
      let allowedExtensions = ['jpg', 'jpeg', 'png']
      /* if (this.$refs.file.files.length < 1) {
        this.submitted = false;
        this.isDisabled = false;
        this.$swal("Oops!", "Course artwork is require. Please a image for your course.", "error");
        return;
      } */
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i]
        formData.append('file', file)
        // Form Validate ...
        let fileName = file.name
        let fileExtension = fileName.replace(/^.*\./, '')
        if (allowedExtensions.includes(fileExtension) == false) {
          let msg = '.' + fileExtension + ' file not allowed. Please replace ' + file.name
          this.$swal('Oops!', msg, 'error')
          this.submitted = false
          this.isDisabled = false
          return
        }
        if (file.size > 2 * 1000 * 1000) {
          let msg = file.name + ' is larger than the allowed file size of 5MB.'
          this.$swal('Oops!', msg, 'error')
          this.submitted = false
          this.isDisabled = false
          return
        }
      }

      formData.append('name', this.course.name)
      formData.append('introduction', this.course.introduction)
      formData.append('prerequisite', this.course.prerequisite)
      formData.append('description', this.course.description)
      formData.append('price', this.course.price)

      axios
        .post(`/author/courses/edit/${this.$route.params.uuid}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          this.submitted = false

          this.isDisabled = false
          if (res.data.status === 'success') {
            this.$v.$reset()
            this.$swal('Done!', res.data.message, 'success')
            this.$router.push({ name: 'CourseDetailsAuthor', params: { uuid: this.$route.params.uuid } })
          } else {
            this.$swal('Done!', res.data.message, 'error')
          }
        })
        .catch(err => {})
    }
  }
}
</script>

<style></style>
