<template>
  <div class="">
    <div class="sidebar" :class="compress ? 'compress' : ''">
      <div class="logo-details">
        <!-- <i class="bx bxl-c-plus-plus"></i> -->
        <div class="logo_icon">
          <img src="../assets/img/JAMB-Logo.png" alt="" height="50" />
        </div>
        <div class="logo_name">Admin</div>
      </div>
      <ul class="nav-links">
        <!-- Dashboard -->
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon-house-door></b-icon-house-door>
            <span class="link_name">Dashboard</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Dashboard</a></li>
          </ul>
        </li>

        <li @click="showSubMenu('Reports')" :class="menu == 'Reports' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-bar-chart></b-icon-bar-chart>
              <span class="link_name">Reports</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Reports</a></li>
            <li><router-link :to="{ name: 'SLACompliance' }">SLA Compliance</router-link></li>
          </ul>
        </li>
      </ul>
    </div>

    <section class="home-section">
      <div class="home-content">
        <!-- <i class="bx bx-menu" @click="toggleMenu()"></i> -->
        <b-icon-list @click="toggleMenu()"></b-icon-list>
        <span class="text"></span>
        <div>
          <b-dropdown id="dropdown-1" :text="authUser.name" class="m-md-2" variant="secondary-outline">
            <!--  <b-dropdown-divider></b-dropdown-divider> -->
            <b-dropdown-item :to="{ name: 'ProfileAdmin' }">Profile</b-dropdown-item>
            <Logout kind="dd-item" />
          </b-dropdown>
        </div>
      </div>
      <div class="cont">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Logout from '@/components/Logout.vue'

export default {
  components: { Logout },
  data() {
    return {
      compress: false,
      menu: '',

      roles: [],
      ticketStatuses: []
    }
  },

  mounted() {
    if (window.innerWidth < 768) {
      this.compress = true
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.screenResize)
  },

  methods: {
    screenResize(e) {
      //console.log(e.srcElement.innerWidth);
      /* console.log(screen.width); */
      if (e.srcElement.innerWidth < 768) {
        this.compress = true
      } else {
        this.compress = false
      }
    },

    toggleMenu() {
      this.compress = !this.compress
    },

    showSubMenu(menu) {
      if (this.menu == menu) {
        this.menu = ''
      } else {
        this.menu = menu
      }
    }

    /*  */
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped></style>
