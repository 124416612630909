import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'

import adminRoutes from './admin'
import authorRoutes from './author'
import candidateRoutes from './candidate'
import publicRoutes from './public'

Vue.use(VueRouter)

const allRoutes = []

const routes = allRoutes.concat(adminRoutes, authorRoutes, candidateRoutes, publicRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueRouterBackButton, {
  router
})

export default router
