<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-9">
                  {{ test.name }}
                </div>
                <div class="col-3 text-right">
                  <countdown :time="timeSet" :auto-start="true" @start="countdownStarted" @progress="countdownProgress" @end="countdownEnded">
                    <template slot-scope="props">
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-sm btn-warning"><b>Time</b></button>
                        <button type="button" class="btn btn-sm btn-danger">
                          <b>{{ props.minutes }} : {{ props.seconds }}</b>
                        </button>
                      </div>
                    </template>
                  </countdown>
                </div>
              </div>
            </div>
            <div class="cont">
              <div class="card-body" style="min-height: 150px">
                <h6 class="card-subtitle mb-2 text-danger">Question: {{ index + 1 }} of {{ scripts.length }}</h6>
                <div class="card-text question" v-html="scripts[index].question.content"></div>
              </div>

              <!-- Answers -->
              <div v-for="(answer, i) in scripts[index].question.answers" :key="i">
                <div @click="selectAnswer(index, answer.uuid)" class="card-footer answers">
                  <i class="icon icon-circle1" v-if="scripts[index].answerUUID !== answer.uuid"></i>
                  <i class="icon icon-check-circle1" v-if="scripts[index].answerUUID === answer.uuid"></i>
                  <span v-html="answer.content"></span>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <!-- feedback -->
              <div class="row">
                <div class="col-3">
                  <button v-if="scripts[index].answerUUID != ''" class="btn btn-secondary" @click="questionFeedback(scripts[index].question.uuid)">Feedback</button>
                </div>

                <div class="col-6 text-center">
                  <button class="btn btn-primary ml-2 mr-2" @click="prev" :disabled="index > 0 ? false : true"><i class="icon icon-arrow-left"></i> Prev</button>
                  <button class="btn btn-primary ml-2 mr-2" @click="next" :disabled="index == this.scripts.length - 1 ? true : false">Next <i class="icon icon-arrow-right"></i></button>
                </div>

                <div class="col-3 text-right">
                  <button class="btn btn-danger ml-2 mr-2" @click="submit">Submit <i class="icon icon-check"></i></button>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
              <span v-for="(script, i) in scripts" :key="i" style="width: 100%">
                <button :class="script.answerUUID === '' ? 'btn-outline-dark' : 'btn-dark'" class="btn btn-sm m-1" @click="navButton(i)">{{ i + 1 }}</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AssessmentRenderCandidate',

  data() {
    return {
      test: {},
      index: 0,
      scripts: [
        {
          answerUUID: '',
          uuid: '',
          question: {
            content: '',
            uuid: '',
            answers: [
              {
                content: '',
                uuid: ''
              }
            ]
          }
        }
      ],
      timeSet: 1 * 60 * 1000 // default time to one minutes
    }
  },

  mounted() {
    axios.get(`/candidate/tests/render/${this.attemptUUID}`).then(res => {
      if (res.data.status == 'success') {
        this.test = res.data.test
        this.scripts = res.data.scripts
        // timeSet is in minutes, it qual test timeset minus minutes used - minus 30 seconds.
        //The 30 seconds is cater for every minute saver report to the server, in case user refresh the render page
        this.timeSet = (res.data.test.timeSet - res.data.attempt.totalMinutesUsed - 0.3) * 60 * 1000
      } else {
        this.$swal('Oops!', res.data.message, 'error')
        this.$router.push({ name: 'AssessmentResultCandidate', params: { attemptUUID: this.attemptUUID } })
      }
    })
  },

  methods: {
    prev() {
      if (this.index != 0) {
        this.index--
      }
    },

    next() {
      if (this.index != this.scripts.length - 1) {
        this.index++
      }
    },

    navButton(index) {
      this.index = index
    },

    selectAnswer(scriptIndex, myAnswerUUID) {
      this.scripts[scriptIndex].answerUUID = myAnswerUUID
      let scriptUUID = this.scripts[scriptIndex].uuid
      axios.post(`/candidate/tests/grade-script/${scriptUUID}/${myAnswerUUID}`).then(res => {
        if (res.data.status === 'failed') {
          alert(res.data.message)
        }
      })
    },

    /* actual test completed */
    testCompleted() {
      axios.post(`/candidate/tests/submit/${this.attemptUUID}`).then(res => {
        if (res.data.status === 'success') {
          this.$swal('Completed!', `You score ${res.data.attempt.score} / ${res.data.attempt.totalQuestionsGiven}`, 'success')
          this.$router.push({ name: 'AssessmentResultCandidate', params: { attemptUUID: this.attemptUUID } })
        } else {
          this.$swal('', res.data.message, 'error')
        }
      })
    },
    /*  */

    submit() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `You have ${this.questionsUnanswered} questions left unanswered. This action will submit and end this test.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, submit it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            this.testCompleted()
            /* end of actural action */
          }
        })
    },

    questionFeedback(qUUID) {
      axios.get(`/candidate/question-feedback/${qUUID}`).then(res => {
        this.$swal('', res.data.message, 'info')
      })
    },

    countdownStarted() {
      console.log('started started')
    },

    countdownProgress(data) {
      //console.log(data.seconds);
      if (data.seconds == 0) {
        // Very minute
        axios.post(`/candidate/tests/save-time/${this.attemptUUID}`).then(res => {
          if (res.data.status === 'success') {
            console.log('Time saver success')
          } else {
            console.log('Time saver failed. Check your network.')
          }
        })
      }
    },

    countdownEnded() {
      console.log('ended ended')
      this.testCompleted()
    }
  },

  computed: {
    attemptUUID() {
      return this.$route.params.attemptUUID
    },

    questionsUnanswered() {
      let scripts = this.scripts.filter(function (data) {
        return data.answerUUID == ''
      })
      return scripts.length
    }
  }
}
</script>

<style scoped>
.cont {
  height: 450px;
  width: 100%;
  overflow-y: auto;
}
.answers {
  /*  background-color: #a8d4ff; */
  /* background-color: #e8f3ff; */
  background-color: #fff;
  cursor: pointer;
}
.answers i {
  font-size: 20px;
  margin-right: 10px;
}
/* unaswered */
.answers .icon-circle1 {
  color: #999;
  background-color: #fff;
}
/* Answered */
.answers .icon-check-circle1 {
  color: #16994c;
  font-weight: bold;
}
</style>
