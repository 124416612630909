<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <BackIcon></BackIcon> Entrolled Students - <small>{{ resource.Name }}</small>
          </div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="students"
              :line-numbers="true"
              :search-options="searchOptions"
              :pagination-options="paginationOptions"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'Name', type: 'asc' }
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <div slot="table-actions">
                <AuthorEntrollUsers :resourceUUID="this.$route.params.resourceUUID" :resourceType="this.$route.params.resourceType" />
              </div>
            </vue-good-table>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
import AuthorEntrollUsers from '../../../components/AuthorEntrollUsers.vue'

export default {
  name: 'ResourceStudents',

  components: {
    AuthorEntrollUsers,
    BackIcon
  },

  data() {
    return {
      resource: {},
      students: []
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/author/entrolled-users/${this.$route.params.resourceUUID}/${this.$route.params.resourceType}`).then(res => {
      if (res.data.status == 'success') {
        this.resource = res.data.resource
        this.students = res.data.students
      } else {
        this.$swal('Ooops!', res.data.message, 'error')
      }
      loading.hide()
    })
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Full Name',
          field: 'Name'
        },
        {
          label: 'Email Address',
          field: 'Email'
        },
        {
          label: 'Phone Number',
          field: 'Phone'
        },
        {
          label: 'Joined At',
          field: 'CreatedAt'
        },
        {
          label: 'Entrolled At',
          field: 'EnrolledAt'
        },
        {
          label: 'No of Lessons',
          field: 'TotalLectures'
        },
        {
          label: 'No. Completed',
          field: 'CompletedLectures'
        },
        {
          label: 'Completion Percent',
          field: 'CompletionPercentage'
        }
      ]
      return cols
    },
    searchOptions() {
      return {
        enabled: true,
        trigger: 'enter'
      }
    },

    paginationOptions() {
      return {
        enabled: true,
        perPage: 100
      }
    }
  }
}
</script>

<style scoped></style>
